import { useCallback } from "react";
import { getBalance } from "@brainhubinc/api";
import { useQuery } from "react-query";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { useToken } from "./useToken";
import { useMetadata } from "./useMetadata";

export const useBalance = (id: string) => {
  const { library, chainId } = useWeb3React<Web3>();
  const token = useToken(id);
  const metadata = useMetadata(id);

  const balanceReq = useCallback(
    getBalance({
      library,
      account: id,
      token: token?.data?.token || undefined,
    }),
    [library, metadata.data, id],
  );

  return useQuery(["contract", "balance", chainId, id], balanceReq, {
    enabled: !!(library && metadata.data && id),
  });
};
