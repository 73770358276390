export type OfferData = {
  id: number;
  title: string;
  description: string;
  rate: number;
  token?: string;
  numberOfDays: number;
  hoursPerDay: number;
  startOffer: string | number | null;

  assignee?: string;
  client?: string | null;
  contractAddress?: string | null;
  chainId?: string | null;

  state?: number | null;
};

export type OffersData = Array<OfferData>;

export type MetadataData = {
  title: string;
  description: string;
  rate: number;
  numberOfDays: number;
  hoursPerDay: number;
  startDate: number | null;
};

export type TimeEntry = {
  date: string;
  numberOfHours: string;
  description: string;
  approved: boolean;
  released: boolean;
};

export enum Role {
  Client = "Client",
  Assignee = "Assignee",
}

export enum States {
  NotStarted = 0,
  Active = 1,
  Stopped = 2,
}
