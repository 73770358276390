export default {
  breakpoints: ["40em", "52em", "64em"],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  colors: {
    black: "#23262F",
    black100: "#374151",
    grey100: "#F7F7F8",
    grey200: "#E6E8EC",
    grey600: "#9D9CA8",
    grey700: "#374151",
    green200: "#D7D495",
    green600: "#BDB74F",
  },
  space: [0, 4, 8, 16, 24, 32, 40, 48, 56, 64, 128, 256],
  sizes: [0, 4, 8, 16, 24, 32, 40, 48, 56, 64, 128, 256],
  fonts: {
    body: "Inter, system-ui, sans-serif",
    heading: "inherit",
  },
  fontWeights: {
    body: 400,
    medium: 500,
    heading: 700,
    semiBold: 600,
    bold: 700,
  },
  lineHeights: {
    medium: 1.4,
    body: 1.5,
    heading: 1.25,
  },
  shadows: {},
  variants: {},
  borderWidths: {
    base: 1,
  },
  radii: {
    base: "6px",
    round: "50%",
  },
  styles: {
    spinner: {
      color: "#4248AE",
    },
  },
  text: {
    h1: {
      fontWeight: "bold",
      fontSize: 4,
      lineHeight: "medium",
    },
    h3: {
      fontWeight: "bold",
      fontSize: 2,
      lineHeight: "heading",
    },
  },
  forms: {
    label: {
      fontSize: 2,
      fontWeight: "bold",
      lineHeight: "heading",
      marginBottom: 3,
      "* + &": {
        marginTop: 4,
      },
    },
  },
  buttons: {
    primary: {
      background: "black",
      paddingY: 2,
      paddingX: 3,
      borderRadius: "base",
      color: "white",
      fontSize: 1,
      fontWeight: "semiBold",
      lineHeight: "body",
    },
    secondary: {
      background: "grey600",
      paddingY: 2,
      paddingX: 3,
      borderRadius: "base",
      color: "white",
      fontSize: 1,
      fontWeight: "semiBold",
      lineHeight: "body",
    },
    warning: {
      background: "#F45C43",
      paddingY: 2,
      paddingX: 3,
      borderRadius: "base",
      color: "white",
      fontSize: 1,
      fontWeight: "semiBold",
      lineHeight: "body",
    },
  },
  messages: {
    danger: {
      display: "flex",
      alignItems: "center",
      paddingX: 3,
      paddingY: 2,
      background: "linear-gradient(90deg, #EB3349 0%, #F45C43 100%)",
      color: "white",
      fontSize: 1,
      fontWeight: "medium",
      borderLeftWidth: 0,
      borderRadius: 0,
    },
  },
  badges: {
    accent: {
      backgroundColor: "#4248AE",
      color: "white",
      padding: 1,
    },
    inProgress: {
      paddingX: 2,
      paddingY: 1,
      borderRadius: "4px",

      fontSize: 0,
      fontWeight: "semiBold",

      background: "#FEF6E3",
      color: "#FFB812",
    },
    success: {
      paddingX: 2,
      paddingY: 1,
      borderRadius: "4px",

      fontSize: 0,
      fontWeight: "semiBold",

      background: "#E7F6DF",
      color: "#62C62D",
    },
  },
};
