import { useAvailableRemainingDeposit } from "./useAvailableRemainingDeposit";
import BN from "bn.js";

export const useIsCanWithdrawRemainingDeposit = (id: string) => {
  const availableRemainingDeposit = useAvailableRemainingDeposit(id);

  return (
    availableRemainingDeposit.data && availableRemainingDeposit.data.gt(new BN(0, 10))
  );
};
