import React from "react";
import { Box, Flex, Text } from "theme-ui";

export const ActiveItem = ({ title }: { title: string }) => {
  return (
    <Flex
      sx={{
        alignItems: "center",
        "* + &": {
          position: "relative",
          marginTop: 4,
          ":before": {
            content: '""',
            position: "absolute",
            width: "1px",
            height: "16px",
            background: "linear-gradient(91.34deg, #4D34B6 45.83%, #2E6C9F 100%)",
            top: "-20px",
            left: "11.5px",
          },
        },
      }}
    >
      <Box
        sx={{
          width: 4,
          height: 4,
          background: "linear-gradient(91.34deg, #4D34B6 45.83%, #2E6C9F 100%)",
          mask: `url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M7 11.9998C7.00017 9.61461 8.6852 7.56167 11.0246 7.0965C13.364 6.63132 15.7062 7.88346 16.6189 10.0872C17.5315 12.2909 16.7604 14.8324 14.7771 16.1574C12.7938 17.4825 10.1507 17.222 8.46419 15.5353C7.52659 14.5976 6.9999 13.3259 7 11.9998Z' fill='currentColor' stroke='currentColor' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/><path fill-rule='evenodd' clip-rule='evenodd' d='M12.9753 7.09639C10.8154 6.66679 8.6288 7.70104 7.59071 9.64322C6.55261 11.5854 6.90755 13.9781 8.46474 15.5353C10.0219 17.0925 12.4146 17.4475 14.3568 16.4094C16.299 15.3713 17.3332 13.1846 16.9036 11.0247' fill='currentColor'/><path d='M12.9753 7.09639C10.8154 6.66679 8.6288 7.70104 7.59071 9.64322C6.55261 11.5854 6.90755 13.9781 8.46474 15.5353C10.0219 17.0925 12.4146 17.4475 14.3568 16.4094C16.299 15.3713 17.3332 13.1846 16.9036 11.0247' stroke='currentColor' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/><rect x='0.75' y='0.75' width='22.5' height='22.5' rx='11.25' stroke='currentColor' stroke-width='1.5'/></svg>")`,
        }}
      />

      <Text
        sx={{
          marginLeft: 3,
          fontSize: 2,
          fontWeight: "medium",
          lineHeight: "body",
        }}
      >
        {title}
      </Text>
    </Flex>
  );
};
