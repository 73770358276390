import { useCallback } from "react";
import { startContract } from "@brainhubinc/api";
import { useMutation, useQueryClient } from "react-query";
import { wait } from "@brainhubinc/commons";
import { toast } from "react-hot-toast";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";

export const useStartMutation = (id: string) => {
  const { library, account, chainId } = useWeb3React<Web3>();
  const queryClient = useQueryClient();

  const startReq = useCallback(
    startContract({
      library,
      account,
      id,
    }),
    [library, account, id],
  );

  return useMutation(startReq, {
    mutationKey: ["start", id],
    onSuccess: async () => {
      await wait(300);
      await queryClient.invalidateQueries(["offers", "byClient"]);
      await queryClient.invalidateQueries(["contract", "state", chainId, id]);
      toast.success(`Contract activated`);
    },
    onError: (e: any) => {
      toast.error(e.message);
    },
  });
};
