import React, { useEffect } from "react";
import pick from "lodash.pick";
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import { Box, Flex, Label, Button } from "theme-ui";
import Web3 from "web3";
import { toast } from "react-hot-toast";
import { useWeb3React } from "@web3-react/core";
import debounce from "lodash.debounce";

import { LoadingContent } from "src/components/loading-content";
import { CustomInput, CustomTextarea } from "src/components/custom-form";
import { CustomDatePicker } from "src/components/date-picker";
import TokensSelect from "src/components/tokens-select";
import { OfferData } from "@brainhubinc/api";
import { fromSolidity, toSolidity } from "@brainhubinc/commons";

import { TokenBalance } from "./TokenBalance";

const containsIllegalSymbols = (str: string) => {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/;
  return specialChars.test(str);
};

const initialValues = {
  title: "",
  description: "",
  rate: 0,
  token: undefined,
  numberOfDays: 0,
  hoursPerDay: 0,
  assignee: "",
  startOffer: null,
};

type FormData = {
  title?: string;
  description?: string;
  rate?: number;
  token?: string;
  numberOfDays?: number;
  hoursPerDay?: number;
  assignee?: string;
  startOffer: string | number | null;
};

const isNullValidation = (value?: number) => value === 0 || value! < 0;

const ContractForm = ({
  id,
  onSave,
  onStart,
  onDelete,
  defaultValues,
  isStartLoading,
  isSaveLoading,
  erc20feePercentage,
  etherFeePercentage,
  chainId,
  accountAddress,
}: {
  accountAddress?: string | null;
  id?: string;
  defaultValues?: OfferData;
  onSave: (data: any) => void;
  onStart: (data: any) => void;
  onDelete: () => void;
  isStartLoading?: boolean;
  isSaveLoading?: boolean;
  erc20feePercentage?: number;
  etherFeePercentage?: number;
  chainId?: number;
}) => {
  const { library } = useWeb3React<Web3>();

  const serializedDefaultValues = defaultValues
    ? pick(defaultValues, Object.keys(initialValues))
    : undefined;

  const {
    handleSubmit,
    watch,
    control,
    reset,
    getValues,
    setError,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: serializedDefaultValues ? serializedDefaultValues : initialValues,
    mode: "all",
  });

  useEffect(() => {
    reset({
      token: undefined,
    });
  }, [chainId]);

  const fetchENSAddress = async () => {
    const formValues = getValues();
    const value = formValues.assignee;
    if (!value || containsIllegalSymbols(value)) {
      setError("assignee", {
        type: "custom",
        message: "Assignee address should be a valid address",
      });
      return;
    }

    try {
      var address = await library?.eth.ens.getAddress(value.trim());
      toast.success("Found an address");
      setValue("assignee", address);
      return;
    } catch (error) {
      console.log("error with fetching ens address", error);
    }

    if (!Web3.utils.isAddress(value!)) {
      setError("assignee", {
        type: "custom",
        message: "Assignee address should be a valid address",
      });
      return;
    }

    if (value === accountAddress) {
      setError("assignee", {
        type: "custom",
        message: "Assignee address must be different from your current address",
      });
      return;
    }
  };

  const debouncedFetchENS = debounce(fetchENSAddress, 1000);

  const token = watch("token", undefined);
  const isERC20 = !!token;

  const feePercentage = isERC20 ? erc20feePercentage : etherFeePercentage;

  const rate = watch("rate", 0);
  const numberOfDays = watch("numberOfDays", 0);
  const hoursPerDay = watch("hoursPerDay", 0);
  const deposit =
    rate! * numberOfDays! * hoursPerDay! * (1 + (feePercentage || 0)) || 0;
  const isDeployBtnDisabled =
    isSaveLoading || isStartLoading || Object.keys(errors).length > 0;

  return (
    <Box>
      <Helmet>
        {!id ? (
          <title>Create new contract | BrainHub</title>
        ) : (
          <title>Edit draft | BrainHub</title>
        )}
      </Helmet>

      <Label htmlFor="title">Title</Label>
      <Controller
        name="title"
        control={control}
        rules={{
          required: "Title is required",
        }}
        render={({ field }) => <CustomInput {...field} error={errors.title} />}
      />

      <Label htmlFor="description">Description</Label>
      <Controller
        name="description"
        control={control}
        render={({ field }) => <CustomTextarea rows={4} {...field} />}
      />

      <Label
        htmlFor="rate"
        sx={{
          "* + &": {
            marginTop: "72px",
          },
        }}
      >
        Rate
      </Label>
      <Flex
        sx={{
          width: "100%",
          alignItems: "flex-start",
        }}
      >
        <Controller
          name="rate"
          control={control}
          rules={{
            required: "Rate is required",
            validate: (value) => {
              if (isNullValidation(value)) {
                return "Rate should be more than 0";
              }
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <CustomInput
              type="number"
              hint="per hour"
              {...field}
              error={error}
              sx={{
                flex: 1,
              }}
            />
          )}
        />
        <Box
          sx={{
            flex: 1,
            marginLeft: 4,
          }}
        >
          <Controller
            name="token"
            control={control}
            render={({ field }) => (
              <TokensSelect
                value={field.value || null}
                onSelect={(item) => {
                  field.onChange({
                    target: {
                      value: item.address,
                    },
                  });
                }}
              />
            )}
          />
          <Box
            sx={{
              marginTop: 1,
            }}
          >
            <TokenBalance token={token} />
          </Box>
        </Box>
      </Flex>

      <Label htmlFor="startOffer">Start date</Label>
      <Controller
        name="startOffer"
        rules={{
          required: "Start date is required",
        }}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <CustomDatePicker
            selected={field.value ? fromSolidity(field.value) : null}
            error={error}
            onChange={(date) => {
              field.onChange({
                target: {
                  value: date && toSolidity(date),
                },
              });
            }}
          />
        )}
      />

      <Label htmlFor="title">Assignee (address or ENS name)</Label>
      <Controller
        rules={{
          required: "Assignee address is required",
          // @ts-ignore
          validate: debouncedFetchENS,
        }}
        name="assignee"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <CustomInput {...field} error={error} />
        )}
      />

      <Label htmlFor="numberOfDays">Contract duration</Label>
      <Flex
        sx={{
          width: "100%",
        }}
      >
        <Controller
          name="numberOfDays"
          control={control}
          rules={{
            required: "Contract duration is required",
            validate: (value) => {
              if (isNullValidation(value)) {
                return "Contract duration should be more than 0";
              }
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <CustomInput
              defaultValue="0"
              type="number"
              hint="working days"
              min="0"
              step="1"
              {...field}
              error={error}
              sx={{
                flex: 1,
              }}
            />
          )}
        />
        <Controller
          name="hoursPerDay"
          control={control}
          rules={{
            required: "Contract duration is required",
            validate: (value) => {
              if (isNullValidation(value)) {
                return "Contract duration should be more than 0";
              }
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <CustomInput
              defaultValue="0"
              type="number"
              hint="hours per day"
              min="0"
              step="1"
              {...field}
              error={error}
              sx={{
                flex: 1,
                marginLeft: 4,
              }}
            />
          )}
        />
      </Flex>

      <Label htmlFor="deposit">Required deposit</Label>
      <CustomInput
        readOnly
        hint={feePercentage ? `include fees ${100 * feePercentage}%` : undefined}
        value={deposit}
      />

      <Flex
        sx={{
          mt: 6,
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        {id && (
          <Flex
            sx={{
              flex: 1,
            }}
          >
            <Button
              variant="warning"
              onClick={() => {
                onDelete();
              }}
              disabled={isSaveLoading || isStartLoading}
            >
              Delete
            </Button>
          </Flex>
        )}
        <Button
          variant="primary"
          onClick={() => {
            const data = getValues();
            if (!data.title) {
              toast.error("Title is required for creating draft");
            } else {
              onSave(getValues());
            }
          }}
          disabled={isSaveLoading || isStartLoading}
          sx={{
            marginLeft: 4,
          }}
        >
          <LoadingContent isLoading={isSaveLoading}>
            {defaultValues ? "Save" : "Create"} a draft
          </LoadingContent>
        </Button>
        <Button
          variant={isDeployBtnDisabled ? "secondary" : "primary"}
          name="start"
          onClick={handleSubmit(onStart)}
          disabled={isDeployBtnDisabled}
          sx={{
            marginLeft: 4,
          }}
        >
          <LoadingContent isLoading={isStartLoading}>
            Deploy smart contract
          </LoadingContent>
        </Button>
      </Flex>
    </Box>
  );
};

export default ContractForm;
