import { Box, Flex, Image, Text } from "theme-ui";
import { MetadataData, Role, States } from "@brainhubinc/api";
import { format } from "date-fns";
import { fromSolidity, TokenInfo } from "@brainhubinc/commons";
import React from "react";
import { StatusBadge } from "./StatusBadge";
import { ActiveItem, CompletedItem, FutureItem } from "./Process";
import { useMetadata } from "../../../hooks/useMetadata";
import { useRole } from "../../../hooks/useRole";
import { useToken } from "../../../hooks/useToken";
import { useContractState } from "../../../hooks/useContractState";
import { useTokenInfo } from "../../../hooks/useTokenInfo";
import { useRateDisplay } from "../../../hooks/useRateDisplay";
import { useIsEnoughDeposit } from "../../../hooks/useIsEnoughDeposit";
import { useIsCanWithdrawRemainingDeposit } from "../../../hooks/useIsCanWithdrawRemainingDeposit";

export const MetadataView = ({
  state,
  role,
  token,
  metadata,
  rateDisplay,
  isEnoughDeposit,
  isCanWithdrawRemainingDeposit,
}: {
  metadata: MetadataData;
  state: number;
  role?: Role | null;
  token?: TokenInfo;
  rateDisplay?: string;
  isEnoughDeposit?: boolean;
  isCanWithdrawRemainingDeposit?: boolean;
}) => {
  let startDateDisplay;
  try {
    startDateDisplay =
      metadata.startDate &&
      `${format(fromSolidity(metadata.startDate), "MM/dd/yyyy")} (UTC)`;
  } catch (e) {
    console.log(e);
  }

  return (
    <Box>
      <Flex
        sx={{
          marginTop: 4,
          alignItems: "center",
        }}
      >
        <Text variant="h3">Role</Text>
        <Box
          sx={{
            marginLeft: 3,
          }}
        >
          <Text
            sx={{
              fontSize: 2,
              lineHeight: "body",
            }}
          >
            {role === Role.Client
              ? "Client"
              : role === Role.Assignee
              ? "Assignee"
              : "Unknown"}
          </Text>
        </Box>
      </Flex>

      <Flex
        sx={{
          marginTop: 4,
          alignItems: "center",
        }}
      >
        <Text variant="h3">State</Text>

        <Box
          sx={{
            marginLeft: 3,
          }}
        >
          <StatusBadge
            text={
              state == States.NotStarted
                ? "Not Started"
                : state == States.Active
                ? "Active"
                : "Finished"
            }
            variant={state == States.NotStarted ? "danger" : "success"}
          />
        </Box>
      </Flex>

      <Box
        sx={{
          marginTop: 3,
        }}
      >
        {state == States.NotStarted && !isEnoughDeposit && (
          <>
            <CompletedItem title="Draft" />
            <CompletedItem title="Contract deployed" />
            <ActiveItem title="Attaching deposit" />
            <FutureItem title="Start" />
            <FutureItem title="Working" />
          </>
        )}

        {state == States.NotStarted && isEnoughDeposit && (
          <>
            <CompletedItem title="Draft" />
            <CompletedItem title="Contract deployed" />
            <CompletedItem title="Deposit attached" />
            <ActiveItem title="Start" />
            <FutureItem title="Working" />
          </>
        )}

        {state == States.Active && (
          <>
            <CompletedItem title="Draft" />
            <CompletedItem title="Contract deployed" />
            <CompletedItem title="Deposit attached" />
            <CompletedItem title="Started" />
            <ActiveItem title="Working" />
            <FutureItem title="Finish" />
          </>
        )}

        {state == States.Stopped && isCanWithdrawRemainingDeposit && (
          <>
            <CompletedItem title="Draft" />
            <CompletedItem title="Contract deployed" />
            <CompletedItem title="Deposit attached" />
            <CompletedItem title="Started" />
            <CompletedItem title="Worked" />
            <ActiveItem title="Withdraw remaining deposit" />
          </>
        )}

        {state == States.Stopped && !isCanWithdrawRemainingDeposit && (
          <>
            <CompletedItem title="Draft" />
            <CompletedItem title="Contract deployed" />
            <CompletedItem title="Deposit attached" />
            <CompletedItem title="Started" />
            <CompletedItem title="Worked" />
            <CompletedItem title="Finished" />
          </>
        )}
      </Box>

      <Flex
        sx={{
          marginTop: 4,
          alignItems: "center",
        }}
      >
        <Text variant="h3">Description</Text>
        <Box
          sx={{
            marginLeft: 3,
          }}
        >
          <Text
            sx={{
              fontSize: 2,
              lineHeight: "body",
            }}
          >
            {metadata.description}
          </Text>
        </Box>
      </Flex>

      <Flex
        sx={{
          marginTop: 4,
          alignItems: "center",
        }}
      >
        <Text variant="h3">Start date</Text>
        <Box
          sx={{
            marginLeft: 3,
          }}
        >
          <Text
            sx={{
              fontSize: 2,
              lineHeight: "body",
            }}
          >
            {startDateDisplay || "-"}
          </Text>
        </Box>
      </Flex>

      <Flex
        sx={{
          marginTop: 4,
          alignItems: "center",
        }}
      >
        <Text variant="h3">Hourly rate</Text>
        <Flex
          sx={{
            marginLeft: 3,
            alignItems: "center",
          }}
        >
          <Text
            sx={{
              fontSize: 2,
              lineHeight: "body",
            }}
          >
            {typeof rateDisplay !== "undefined" ? rateDisplay : "0"}
          </Text>
          {token ? (
            <>
              <Image
                src={token.logoURI}
                sx={{
                  marginLeft: 2,
                  width: "20px",
                  height: "20px",
                }}
              />
              <Text
                sx={{
                  fontSize: 1,
                  fontWeight: "bold",
                  lineHeight: "body",
                  marginLeft: 2,
                }}
              >
                {token.name}
              </Text>
            </>
          ) : (
            <Text
              sx={{
                fontSize: 1,
                fontWeight: "bold",
                lineHeight: "body",
                marginLeft: 2,
              }}
            >
              {"Unknown token"}
            </Text>
          )}
        </Flex>
      </Flex>

      <Flex
        sx={{
          marginTop: 4,
          alignItems: "center",
        }}
      >
        <Text variant="h3">Contract duration</Text>
        <Flex
          sx={{
            marginLeft: 3,
          }}
        >
          <Text
            sx={{
              fontSize: 2,
              lineHeight: "body",
            }}
          >
            {metadata.numberOfDays} working days
          </Text>
          <Text
            sx={{
              fontSize: 2,
              lineHeight: "body",
              marginLeft: 3,
            }}
          >
            {metadata.hoursPerDay} hours per day
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

const MetadataViewConnected = ({ id }: { id: string }) => {
  const metadata = useMetadata(id);
  const role = useRole(id);
  const token = useToken(id);
  const state = useContractState(id);
  const tokenInfo = useTokenInfo(token?.data?.token || undefined);
  const rateDisplay = useRateDisplay(id);
  const isEnoughDeposit = useIsEnoughDeposit(id);
  const isCanWithdrawRemainingDeposit = useIsCanWithdrawRemainingDeposit(id);

  if (!metadata.data) {
    return null;
  }

  return (
    <MetadataView
      state={state.data || States.NotStarted}
      role={role.data}
      token={tokenInfo}
      metadata={metadata.data}
      rateDisplay={rateDisplay}
      isEnoughDeposit={isEnoughDeposit}
      isCanWithdrawRemainingDeposit={isCanWithdrawRemainingDeposit}
    />
  );
};

export default MetadataViewConnected;
