import { useCallback } from "react";
import { attachDeposit } from "@brainhubinc/api";
import { useMutation, useQueryClient } from "react-query";
import { wait } from "@brainhubinc/commons";
import { toast } from "react-hot-toast";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { useToken } from "./useToken";
import { useMetadata } from "./useMetadata";
import { useStartMutation } from "./useStartMutation";
import { useMissingBalance } from "./useMissingBalance";

export const useAttachDepositMutation = (id: string) => {
  const { library, chainId, account } = useWeb3React<Web3>();
  const token = useToken(id);
  const metadata = useMetadata(id);
  const requiredDeposit = useMissingBalance(id);
  const queryClient = useQueryClient();
  const startMutation = useStartMutation(id);

  const attachDepositReq = useCallback(
    attachDeposit({
      library,
      metadata: metadata.data,
      requiredDeposit,
      account,
      depositAddress: id,
      token: token.data?.token,
    }),
    [library, account, id, metadata, requiredDeposit, token.data],
  );

  return useMutation(attachDepositReq, {
    onSuccess: async () => {
      await wait(400);
      await queryClient.invalidateQueries(["contract", "balance"]);
      toast.success(`Successfully attached deposit`);
      await wait(200);
      toast.loading("Starting contract", {
        duration: 1000,
      });
      startMutation.mutate();
      analytics.track("Attaching deposit successful", {
        id,
      });
    },
    onError: (e: any) => {
      toast.error(e.message);
      analytics.track("Attaching deposit fail", {
        id,
      });
    },
  });
};
