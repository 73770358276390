import React from "react";
import { Box, Flex, Text } from "theme-ui";

export const ContractsListSubHeader = ({ title }: { title: string }) => {
  return (
    <>
      <Box
        sx={{
          "* + &": {
            width: "100%",
            height: 3,
            borderBottom: "solid 1px #E6E8EC",
            background: "grey200",
          },
        }}
      />
      <Flex
        sx={{
          px: 5,
          py: 3,
          borderBottom: "solid 1px #E6E8EC",
        }}
      >
        <Text
          sx={{
            fontSize: 2,
            fontWeight: "medium",
          }}
        >
          {title}
        </Text>
      </Flex>
    </>
  );
};
