import { toEther } from "@brainhubinc/commons";
import { useMetadata } from "./useMetadata";
import BN from "bn.js";
import { useQuery } from "react-query";
import { getCalcFee } from "@brainhubinc/api";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { useToken } from "./useToken";
import { useTokenInfo } from "./useTokenInfo";

export const useCalcDeposit = ({
  id,
  numberOfDays,
  hoursPerDay,
}: {
  id: string;
  numberOfDays?: number;
  hoursPerDay?: number;
}) => {
  const { library, chainId } = useWeb3React<Web3>();
  const metadata = useMetadata(id);
  const token = useToken(id);
  const tokenInfo = useTokenInfo(token?.data?.token || undefined);

  const deposit =
    metadata.data &&
    new BN(metadata.data.rate, 10)
      .mul(new BN(numberOfDays || metadata.data.numberOfDays, 10))
      .mul(new BN(hoursPerDay || metadata.data.hoursPerDay, 10));

  const fee = useQuery(
    ["fee", id, deposit],
    () => getCalcFee({ library, id })(deposit?.toString()),
    {
      enabled: !!(library && id && deposit),
    },
  );
  const depositWithFee = fee.data && deposit?.add(new BN(fee.data, 10));
  const depositWithFeeDisplay =
    depositWithFee &&
    tokenInfo &&
    library?.utils.fromWei(toEther(depositWithFee, tokenInfo.decimals));
  return {
    depositWithFee,
    depositWithFeeDisplay,
  };
};
