import { useMemo } from "react";
import { toEther } from "@brainhubinc/commons";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { useToken } from "./useToken";
import { useTokenInfo } from "./useTokenInfo";
import { useBalance } from "./useBalance";
import { useAvailableReward } from "./useAvailableReward";
import { useAvailableFee } from "./useAvailableFee";
import BN from "bn.js";

export const useBalanceDisplay = (id: string) => {
  const { library } = useWeb3React<Web3>();
  const token = useToken(id);
  const tokenInfo = useTokenInfo(token?.data?.token || undefined);
  const balance = useBalance(id);

  const availableReward = useAvailableReward(id);
  const availableFee = useAvailableFee(id);

  return useMemo(
    () =>
      balance.data &&
      availableReward.data &&
      availableFee.data &&
      tokenInfo &&
      library?.utils.fromWei(
        toEther(
          new BN(balance.data, 10).sub(availableReward.data).sub(availableFee.data),
          tokenInfo.decimals,
        ),
      ),
    [balance.data, tokenInfo],
  );
};
