import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { wait } from "@brainhubinc/commons";
import { toast } from "react-hot-toast";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { restartContract } from "@brainhubinc/api";
import { useHistory } from "react-router-dom";

export const useRestartMutation = (id: string) => {
  const { library, account, chainId } = useWeb3React<Web3>();
  const queryClient = useQueryClient();
  const history = useHistory();

  const restartReq = useCallback(
    restartContract({
      library,
      account,
      id,
    }),
    [library, account, id],
  );

  return useMutation(restartReq, {
    mutationKey: ["restart", id],
    onSuccess: async (data, variables) => {
      await wait(400);
      queryClient.setQueryData(["contract", "state", chainId, id], () => "0");
      queryClient.setQueryData(["contract", "data", chainId, id], (prev: any) => ({
        ...prev,
        startDate: variables.startDate,
        numbersOfDays: variables.numberOfDays,
        hoursPerDay: variables.hoursPerDay,
      }));
      await queryClient.invalidateQueries(["offers", "byClient"]);
      queryClient.invalidateQueries(["offer", id]);
      toast.success(`Contract restarted`);
      history.push("/contracts/" + id, { replace: true });
    },
    onError: (e: any) => {
      toast.error(e.message);
    },
  });
};
