import React from "react";
import { Box } from "theme-ui";
import { FieldError } from "react-hook-form";

export const GradientBorderContainer = ({
  children,
  error,
}: {
  children: React.ReactNode;
  error?: FieldError;
}) => {
  return (
    <Box
      sx={{
        padding: "1px",
        background: "grey100",
        borderRadius: "base",
        overflow: "hidden",
        ":focus-within": {
          background: "linear-gradient(91.34deg, #4D34B6 45.83%, #2E6C9F 100%)",
        },
        border: "0px solid",
        ...(error
          ? {
              background: "#F45C43",
            }
          : {}),
      }}
    >
      {children}
    </Box>
  );
};
