import { Flex, Heading, Button, Text } from "theme-ui";
import { Icon } from "../icon";
import { Link } from "react-router-dom";
import React from "react";

export const ContractsListHeader = () => {
  return (
    <Flex
      sx={{
        p: 5,
        alignItems: "center",
        borderBottom: "solid 1px #E6E8EC",
      }}
    >
      <Heading
        variant={"h1"}
        sx={{
          flex: 1,
        }}
      >
        Contracts
      </Heading>

      <Link
        to="/contracts/new"
        style={{
          textDecoration: "none",
        }}
      >
        <Button
          as="div"
          variant="primary"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Text>New contract</Text>
          <Icon
            variant="Plus"
            sx={{
              marginLeft: 2,
            }}
          />
        </Button>
      </Link>
    </Flex>
  );
};
