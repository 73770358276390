import React from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Flex, Text } from "theme-ui";

import { TokenInfo } from "@brainhubinc/commons";
import { useTokenInfo } from "src/hooks/useTokenInfo";
import { useWithdrawRewardMutation } from "src/hooks/useWithdrawRewardMutation";
import { useAvailableRewardDisplay } from "src/hooks/useAvailableRewardDisplay";
import { useAvailableReward } from "src/hooks/useAvailableReward";
import { useToken } from "src/hooks/useToken";
import { useClient } from "src/hooks/useClient";

import { BlockchainAddress, TypesOfAddress } from "../components/BlockchainAddress";
import { BalanceContainer } from "../components/BalanceContainer";
import { ControlContainer } from "../components/ControlContainer";
import { LoadingContent } from "../../loading-content";
import BN from "bn.js";

type Props = {
  contractAddress?: string;
  clientAddress?: string;
  token?: TokenInfo;
  availableRewardDisplay?: string;
  isLoading?: boolean;
  availableReward?: BN;
  onWithdrawReward?: () => void;
};

export const ControlAssignee = ({
  contractAddress,
  clientAddress,
  token,
  availableRewardDisplay,
  isLoading,
  availableReward,
  onWithdrawReward,
}: Props) => {
  return (
    <ControlContainer>
      <BlockchainAddress address={contractAddress} />
      {clientAddress ? (
        <Box
          sx={{
            marginTop: 3,
          }}
        >
          <BlockchainAddress
            address={clientAddress}
            typeOfAddress={TypesOfAddress.Client}
          />
        </Box>
      ) : null}
      <Box
        sx={{
          marginTop: 6,
        }}
      >
        <Text variant="h3">Available Reward</Text>
        <Flex
          sx={{
            marginTop: 3,
            justifyContent: "space-between",
          }}
        >
          <BalanceContainer token={token}>
            <Text>
              {typeof availableRewardDisplay === "undefined"
                ? "Unknown"
                : availableRewardDisplay}
            </Text>
          </BalanceContainer>
          <Flex
            sx={{
              alignItems: "center",
            }}
          >
            {(availableReward && availableReward.gt(new BN(0, 10)) && (
              <Button
                variant="primary"
                onClick={onWithdrawReward}
                disabled={isLoading}
                sx={{
                  marginLeft: 3,
                }}
              >
                <LoadingContent isLoading={isLoading}>Withdraw</LoadingContent>
              </Button>
            )) || <></>}
          </Flex>
        </Flex>
      </Box>
    </ControlContainer>
  );
};

const ControlAssigneeConnected = () => {
  const { id } = useParams<{ id: string }>();
  const token = useToken(id);
  const tokenInfo = useTokenInfo(token?.data?.token || undefined);
  const availableReward = useAvailableReward(id);
  const availableRewardDisplay = useAvailableRewardDisplay(id);
  const withdrawRewardMutation = useWithdrawRewardMutation(id);
  const [clientAddress] = useClient(id);

  return (
    <ControlAssignee
      contractAddress={id}
      clientAddress={clientAddress}
      token={tokenInfo}
      availableRewardDisplay={availableRewardDisplay}
      isLoading={withdrawRewardMutation.isLoading}
      availableReward={availableReward.data}
      onWithdrawReward={() => {
        withdrawRewardMutation.mutate();
        analytics.track("Withdraw Reward call", {
          id,
        });
      }}
    />
  );
};

export default ControlAssigneeConnected;
