import React from "react";
import { Flex } from "theme-ui";

export const ControlContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Flex
      sx={{
        paddingY: 5,
        paddingX: 9,
        flexDirection: "column",
        background: "grey200",
        width: "100%",
      }}
    >
      {children}
    </Flex>
  );
};
