import { Box, Heading } from "theme-ui";
import React from "react";

export const ContractCard = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <Box
      sx={{
        paddingTop: 5,
        paddingX: 9,
        paddingBottom: 6,
        background: "white",
        width: "100%",
        overflowY: "auto",
      }}
    >
      <Heading variant="h1">{title}</Heading>

      <Box
        sx={{
          mt: 4,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
