import {
  Table,
  TableCell,
  TableHeader,
  TableHeaderItem,
  TableRow,
  TableSpacer,
} from "../table";
import { useParams } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  approveTimeEntry,
  getRole,
  getTimeEntries,
  Role,
  States,
  TimeEntry,
} from "@brainhubinc/api";
import { format } from "date-fns";
import { Badge, Box, Button, Text } from "theme-ui";
import { fromSolidity, wait } from "@brainhubinc/commons";
import { useCallback } from "react";
import { toast } from "react-hot-toast";
import React from "react";
import { useRole } from "../../hooks/useRole";
import { useContractState } from "../../hooks/useContractState";

const ApproveButtonConnected = ({ timeEntry }: { timeEntry: TimeEntry }) => {
  const { id } = useParams<{ id: string }>();
  const { library, chainId, account } = useWeb3React<Web3>();
  const queryClient = useQueryClient();

  const approveTimeEntryReq = useCallback(
    approveTimeEntry({
      library,
      account,
      id,
    }),
    [library, account, id],
  );

  const approveTimeEntryMutation = useMutation(approveTimeEntryReq, {
    onSuccess: async (data) => {
      await wait(300);
      queryClient.invalidateQueries(["contract", "time-entries", id]);
      queryClient.invalidateQueries(["contract", "state", chainId, id]);
      toast.success(`Approved time entry`);
      analytics.track("Approve Time Entry successful", {
        id,
        ...timeEntry,
      });
    },
    onError: (error: any) => {
      toast.error(error.message);
      analytics.track("Approve Time Entry fail", {
        id,
        ...timeEntry,
      });
    },
  });

  return (
    <Button
      variant="primary"
      sx={{
        paddingX: 2,
        paddingY: 1,
        fontSize: 0,
        fontWeight: "semiBold",
        lineHeight: "20px",
      }}
      onClick={() => {
        approveTimeEntryMutation.mutate({
          date: parseInt(timeEntry.date),
        });
        analytics.track("Approve Time Entry call", {
          id,
        });
      }}
      disabled={approveTimeEntryMutation.isLoading}
    >
      Approve
    </Button>
  );
};

export const TimeEntriesTable = ({
  items,
  role,
  state,
}: {
  items: TimeEntry[] | null;
  role?: Role | null;
  state?: number;
}) => {
  return (
    <Table>
      <TableHeader>
        <TableHeaderItem
          title={"Description"}
          sx={{
            width: "100%",
          }}
        />
        <TableHeaderItem title={"Date"} />
        <TableHeaderItem title={"Working hours"} />
        <TableHeaderItem title={"Status"} />
        <TableHeaderItem title={""} />
      </TableHeader>
      <TableSpacer />
      {items &&
        items.map((item) => {
          let dateDisplay = "";
          try {
            dateDisplay = `${format(fromSolidity(item.date), "MM/dd/yyyy")} (UTC)`;
          } catch (e) {
            console.log(e);
          }
          return (
            <TableRow key={item.date}>
              <TableCell text={item.description} />
              <TableCell
                text={dateDisplay}
                sx={{
                  fontWeight: "medium",
                }}
              />
              <TableCell
                text={item.numberOfHours ? item.numberOfHours + "h" : ""}
                sx={{
                  fontWeight: "medium",
                  textAlign: "center",
                }}
              />
              <TableCell>
                {item.released ? (
                  <Badge variant="success">Released</Badge>
                ) : item.approved ? (
                  <Badge variant="success">Approved</Badge>
                ) : (
                  <Badge variant="inProgress">Added</Badge>
                )}
              </TableCell>
              {role === Role.Client && state == States.Active && (
                <TableCell>
                  {!item.approved && <ApproveButtonConnected timeEntry={item} />}
                </TableCell>
              )}
            </TableRow>
          );
        })}
    </Table>
  );
};

const TimeEntriesTableConnected = () => {
  const { id } = useParams<{ id: string }>();
  const { library } = useWeb3React<Web3>();

  const timeEntries = useQuery(
    ["contract", "time-entries", id],
    getTimeEntries({ library, id }),
    {
      enabled: !!(id && library),
    },
  );

  const role = useRole(id);
  const state = useContractState(id);

  if (timeEntries.data && timeEntries.data.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          padding: 3,
        }}
      >
        <Text
          sx={{
            fontSize: 2,
            textAlign: "center",
          }}
        >
          Don't have time entries
        </Text>
      </Box>
    );
  }

  return (
    <TimeEntriesTable
      items={timeEntries.data || null}
      role={role.data}
      state={state.data}
    />
  );
};

export default TimeEntriesTableConnected;
