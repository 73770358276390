import AuthPage from "./pages/auth";
import { DashboardPage } from "./pages/dashboard";
import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useRedirectToAuthPage } from "./hooks/useRedirectToAuthPage";
import { useUpdateEffect } from "react-use";

export function App() {
  const location = useLocation();
  useRedirectToAuthPage("/auth");

  useUpdateEffect(() => {
    analytics.page();
  }, [location.pathname]);

  return (
    <Switch location={location}>
      <Route exact path="/auth" component={AuthPage} />
      <Redirect to="/contracts" from="/" exact />
      <Route path="/contracts" component={DashboardPage} />
    </Switch>
  );
}
