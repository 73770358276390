import { Box, Flex, Image, Text } from "theme-ui";
import { Logo } from "../logo";
import { AsideMenuItem } from "./AsideMenuItem";
import { useWeb3React } from "@web3-react/core";
import { NetworkKey, networks, NetworkInfo } from "@brainhubinc/commons";
import Web3 from "web3";
import React, { useState } from "react";
import NetworkModal from "../networks-modal";
import { AttentionIcon } from "../attention-icon";

export const AsideMenu = ({
  signer,
  networkInfo,
  onLogout,
}: {
  signer: string | null;
  networkInfo: NetworkInfo | null;
  onLogout?: () => void;
}) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <Flex
      sx={{
        background: "#0D0C0C",
        padding: 5,
        color: "white",
        width: 300,
        flexShrink: 0,
        flexDirection: "column",
      }}
    >
      <Logo
        title={signer ? signer.slice(0, 9) + "..." + signer.slice(-5) : "BrainHub"}
      />

      <Box
        sx={{
          mt: 7,
          flex: 1,
        }}
      >
        <AsideMenuItem icon="Tasks" title="Contracts" active />
        <AsideMenuItem
          icon="Notifications"
          title="Notifications"
          future
          sx={{
            mt: 1,
          }}
        />
      </Box>

      <Box
        sx={{
          mt: 2,
        }}
      >
        <a
          href="https://brainhub.canny.io/"
          target="_blank"
          style={{
            display: "block",
            textDecoration: "none",
          }}
        >
          <AsideMenuItem icon="Issue" title="Feedback" minor />
        </a>

        <a
          href="/docs/docs/intro/"
          target="_blank"
          style={{
            display: "block",
            textDecoration: "none",
          }}
        >
          <AsideMenuItem
            icon="Question"
            title="Docs"
            minor
            sx={{
              mt: 2,
            }}
          />
        </a>
        <AsideMenuItem
          icon="Logout"
          title="Log out"
          minor
          sx={{
            mt: 2,
          }}
          onClick={onLogout}
        />
      </Box>

      <Box
        sx={{
          width: "100%",
          borderTopColor: "grey600",
          borderTopWidth: "1px",
          borderStyle: "solid",
          mt: 5,
          pt: 5,
        }}
      >
        <Flex
          sx={{
            height: 6,
            px: 1,
            width: "100%",
            alignItems: "center",
            background: "transparent",
            border: "none",
            color: "white",
            "&:hover": {
              background: "linear-gradient(90.14deg, #4248AE 30.34%, #2E6C9F 100%)",
            },
          }}
          onClick={() => setOpen(true)}
          as={"button"}
        >
          {networkInfo ? (
            <Image
              alt={networkInfo.params.chainName}
              src={networkInfo.icon}
              sx={{
                width: 4,
                height: 4,
                borderRadius: "round",
                overflow: "hidden",
              }}
            />
          ) : (
            <AttentionIcon small />
          )}
          <Text
            sx={{
              ml: 4,
              fontSize: 1,
              fontWeight: "medium",
            }}
          >
            {networkInfo ? networkInfo.params.chainName : "Unsupported Chain"}
          </Text>
        </Flex>
      </Box>
      <NetworkModal isOpen={isOpen} onClose={() => setOpen(false)} />
    </Flex>
  );
};

const AsideMenuConnected = () => {
  const { library, chainId, account, deactivate } = useWeb3React();
  const networkInfo =
    (chainId && networks[(library as Web3).utils.toHex(chainId) as NetworkKey]) || null;

  return (
    <AsideMenu
      signer={account || null}
      networkInfo={networkInfo}
      onLogout={() => {
        localStorage.removeItem("provider");
        deactivate();
      }}
    />
  );
};

export default AsideMenuConnected;
