import { useCallback } from "react";
import { withdrawRemainingDeposit } from "@brainhubinc/api";
import { useMutation, useQueryClient } from "react-query";
import { wait } from "@brainhubinc/commons";
import { toast } from "react-hot-toast";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";

export const useWithdrawRemainingDepositMutation = (id: string) => {
  const { library, account, chainId } = useWeb3React<Web3>();
  const queryClient = useQueryClient();

  const withdrawRemainingDepositReq = useCallback(
    withdrawRemainingDeposit({
      library,
      account,
      id,
    }),
    [library, account, id],
  );

  return useMutation(withdrawRemainingDepositReq, {
    mutationKey: ["withdrawRemainingDeposit", id],
    onSuccess: async () => {
      await wait(300);
      await queryClient.invalidateQueries(["availableRemainingDeposit", chainId, id]);
      toast.success(`Withdrawal remaining deposit`);
    },
    onError: (e: any) => {
      toast.error(e.message);
    },
  });
};
