import { useRequiredDeposit } from "./useRequiredDeposit";
import { useMemo } from "react";
import { toEther } from "@brainhubinc/commons";
import { useTokenInfo } from "./useTokenInfo";
import { useToken } from "./useToken";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";

export const useRequiredDepositDisplay = (id: string) => {
  const { library, chainId, account } = useWeb3React<Web3>();
  const requiredDeposit = useRequiredDeposit(id);
  const token = useToken(id);
  const tokenInfo = useTokenInfo(token?.data?.token || undefined);

  return useMemo(
    () =>
      requiredDeposit &&
      tokenInfo &&
      library?.utils.fromWei(toEther(requiredDeposit, tokenInfo.decimals)),
    [requiredDeposit, tokenInfo],
  );
};
