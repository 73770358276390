import React from "react";
import { Box, TextareaProps, Textarea } from "theme-ui";
import { GradientBorderContainer } from "./GradientBorderContainer";

export const CustomTextarea = ({ sx, ...otherProps }: TextareaProps) => {
  return (
    <GradientBorderContainer>
      <Textarea
        {...otherProps}
        sx={{
          fontFamily: "inherit",
          resize: "vertical",
          paddingY: 2,
          paddingX: 3,
          background: "grey100",
          border: "none",
          borderRadius: "5px",
          fontSize: 2,
          color: "black",
          lineHeight: "body",
          marginBottom: 0,
          ":focus-visible": {
            outline: "none",
          },
        }}
      />
    </GradientBorderContainer>
  );
};
