import { Box, Button } from "theme-ui";
import React from "react";
import { Icon } from "../icon";

export const Modal = ({
  children,
  onClose,
}: {
  children: React.ReactNode;
  onClose?: () => void;
}) => {
  return (
    <Box
      onClick={onClose}
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 1,
        background: "rgba(0,0,0,.3)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&.modal-inner-enter": {
          opacity: 0,
        },
        "&.modal-inner-enter-active": {
          opacity: 1,
          transition: "opacity 300ms ease-in",
        },
        "&.modal-inner-exit ": {
          opacity: 1,
        },
        "&.modal-inner-exit-active": {
          opacity: 0,
          transition: "opacity 400ms ease-out",
        },
      }}
    >
      <Box
        onClick={(e) => e.stopPropagation()}
        sx={{
          width: "350px",
          overflowY: "auto",
          background: "white",
          position: "relative",
          ".modal-inner-enter &": {
            transform: "translateY(100%)",
          },
          ".modal-inner-enter-active &": {
            transform: "translateY(0)",
            transition: "transform 300ms ease-in",
          },
          ".modal-inner-exit &": {
            transform: "translateY(0)",
          },
          ".modal-inner-exit-active &": {
            transform: "translateY(100%)",
            transition: "transform 400ms ease-out",
          },
        }}
      >
        <Button
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 5,
            top: 5,
            background: "transparent",
            width: 4,
            height: 4,
            padding: 0,
            color: "black",
            cursor: "pointer",
            marginTop: "-6px",
            marginLeft: "6px",
          }}
        >
          <Icon variant="Cross" />
        </Button>
        {children}
      </Box>
    </Box>
  );
};
