import { Flex } from "theme-ui";
import React from "react";
import AttentionIconIcon from "./attention-icon.svg";

export const AttentionIcon = ({ small }: { small?: boolean }) => {
  return (
    <Flex
      sx={{
        width: small ? 4 : 5,
        height: small ? 4 : 5,
        borderRadius: "round",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        background: "linear-gradient(90deg, #EB3349 0%, #F45C43 100%)",
      }}
    >
      <AttentionIconIcon
        width={small ? "12.75px" : "20px"}
        height={small ? "11.25px" : "17px"}
        viewBox={small ? "0 0 20 17" : "0 0 20 17"}
        style={{ marginTop: small ? "-1px" : "-3px" }}
      />
    </Flex>
  );
};
