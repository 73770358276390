import React from "react";
import { Link, useParams } from "react-router-dom";
import { Box, Button, Flex, Text } from "theme-ui";

import { TokenInfo } from "@brainhubinc/commons";
import { useToken } from "src/hooks/useToken";
import { useTokenInfo } from "src/hooks/useTokenInfo";
import { useRequiredDepositDisplay } from "src/hooks/useRequiredDepositDisplay";
import { useWithdrawRemainingDepositMutation } from "src/hooks/useWithdrawRemainingDepositMutation";
import { useAvailableRemainingDepositDisplay } from "src/hooks/useAvailableRemainingDepositDisplay";
import { useIsCanWithdrawRemainingDeposit } from "src/hooks/useIsCanWithdrawRemainingDeposit";
import { useAssignee } from "src/hooks/useAssignee";

import { BlockchainAddress, TypesOfAddress } from "../components/BlockchainAddress";
import { BalanceContainer } from "../components/BalanceContainer";
import { ControlContainer } from "../components/ControlContainer";
import { LoadingContent } from "../../loading-content";
import { useIsMutating } from "react-query";

type Props = {
  contractAddress?: string;
  assigneeAddress?: string;
  token?: TokenInfo;
  onWithdrawRemainingDeposit?: () => void;
  isLoading?: boolean;
  requiredDepositDisplay?: string;
  availableRemainingDepositDisplay?: string;
  isCanWithdrawRemainingDeposit?: boolean;
};

export const ControlWithdrawRemainingDeposit = ({
  contractAddress,
  assigneeAddress,
  token,
  onWithdrawRemainingDeposit,
  isLoading,
  requiredDepositDisplay,
  availableRemainingDepositDisplay,
  isCanWithdrawRemainingDeposit,
}: Props) => {
  return (
    <ControlContainer>
      <BlockchainAddress address={contractAddress} />
      {assigneeAddress ? (
        <Box
          sx={{
            marginTop: 3,
          }}
        >
          <BlockchainAddress
            address={assigneeAddress}
            typeOfAddress={TypesOfAddress.Assignee}
          />
        </Box>
      ) : null}
      <Box
        sx={{
          marginTop: 6,
        }}
      >
        {isCanWithdrawRemainingDeposit ? (
          <Text variant="h3">Available remaining deposit</Text>
        ) : (
          <Text variant="h3">Remaining deposit already released</Text>
        )}
        <Flex
          sx={{
            marginTop: 3,
            justifyContent: "space-between",
          }}
        >
          <BalanceContainer token={token}>
            {isCanWithdrawRemainingDeposit ? (
              <Text>
                {typeof availableRemainingDepositDisplay !== "undefined"
                  ? availableRemainingDepositDisplay
                  : "Unknown"}
              </Text>
            ) : (
              <Text>
                {typeof requiredDepositDisplay !== "undefined"
                  ? requiredDepositDisplay
                  : "Unknown"}
              </Text>
            )}
          </BalanceContainer>

          <Flex
            sx={{
              alignItems: "center",
            }}
          >
            <Link
              to={`/contracts/${contractAddress}/restart`}
              style={{
                textDecoration: "none",
              }}
            >
              <Button variant="primary">Restart</Button>
            </Link>
            {isCanWithdrawRemainingDeposit && (
              <Box
                sx={{
                  marginLeft: 3,
                }}
              >
                <Button
                  variant="primary"
                  onClick={onWithdrawRemainingDeposit}
                  disabled={isLoading}
                >
                  <LoadingContent isLoading={isLoading}>
                    Withdraw remaining deposit
                  </LoadingContent>
                </Button>
              </Box>
            )}
          </Flex>
        </Flex>
      </Box>
    </ControlContainer>
  );
};

const ControlWithdrawRemainingDepositConnected = () => {
  const { id } = useParams<{ id: string }>();
  const token = useToken(id);
  const tokenInfo = useTokenInfo(token?.data?.token || undefined);
  const requiredDepositDisplay = useRequiredDepositDisplay(id);
  const availableRemainingDepositDisplay = useAvailableRemainingDepositDisplay(id);
  const isCanWithdrawRemainingDeposit = useIsCanWithdrawRemainingDeposit(id);
  const withdrawRemainingDepositMutation = useWithdrawRemainingDepositMutation(id);
  const [assigneeAddress] = useAssignee(id);

  const isWithdrawRemainingDepositMutating = useIsMutating([
    "withdrawRemainingDeposit",
    id,
  ]);

  return (
    <ControlWithdrawRemainingDeposit
      contractAddress={id}
      assigneeAddress={assigneeAddress}
      token={tokenInfo}
      onWithdrawRemainingDeposit={() => {
        withdrawRemainingDepositMutation.mutate();
        analytics.track("Withdraw Remaining Deposit call", {
          id,
        });
      }}
      isLoading={
        withdrawRemainingDepositMutation.isLoading ||
        isWithdrawRemainingDepositMutating > 0
      }
      requiredDepositDisplay={requiredDepositDisplay}
      availableRemainingDepositDisplay={availableRemainingDepositDisplay}
      isCanWithdrawRemainingDeposit={isCanWithdrawRemainingDeposit}
    />
  );
};

export default ControlWithdrawRemainingDepositConnected;
