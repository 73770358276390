import { NetworkInfo } from "./NetworkInfo";

export const MoonbaseTestnet: NetworkInfo = {
  icon: "https://bhst.fra1.digitaloceanspaces.com/network-icons/Moonbeam-Favicon-50.png",
  erc20TimeAndMaterialFactoryAddress: "0x7E66a4aeDdcbFb5d106dB614b42f1636F6819619",
  etherTimeAndMaterialFactoryAddress: "0x3cCa749Bb088F613bE82Ad1532a0890a74A220de",
  params: {
    chainId: "0x507",
    chainName: "Moonbase Alpha",
    nativeCurrency: {
      name: "DEV",
      symbol: "DEV",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.api.moonbase.moonbeam.network/"],
    blockExplorerUrls: ["https://moonbeam.moonscan.io/"],
  },
  tokens: [
    {
      chainId: 1287,
      address: "0x37822de108AFFdd5cDCFDaAa2E32756Da284DB85",
      symbol: "MERC",
      name: "Mercury",
      decimals: 18,
      logoURI: "https://moonbase-minterc20.netlify.app/logos/mercury.svg",
      tags: [],
    },
    {
      chainId: 1287,
      address: "0xCdF746C5C86Df2c2772d2D36E227B4c0203CbA25",
      symbol: "VEN",
      name: "Venus",
      decimals: 18,
      logoURI: "https://moonbase-minterc20.netlify.app/logos/venus.svg",
      tags: [],
    },
    {
      chainId: 1287,
      address: "0x08B40414525687731C23F430CEBb424b332b3d35",
      symbol: "ERTH",
      name: "Earth",
      decimals: 18,
      logoURI: "https://moonbase-minterc20.netlify.app/logos/earth.svg",
      tags: [],
    },
    {
      chainId: 1287,
      address: "0x1FC56B105c4F0A1a8038c2b429932B122f6B631f",
      symbol: "MARS",
      name: "Mars",
      decimals: 18,
      logoURI: "https://moonbase-minterc20.netlify.app/logos/mars.svg",
      tags: [],
    },
    {
      chainId: 1287,
      address: "0x9Aac6FB41773af877a2Be73c99897F3DdFACf576",
      symbol: "JUP",
      name: "Jupiter",
      decimals: 18,
      logoURI: "https://moonbase-minterc20.netlify.app/logos/jupiter.svg",
      tags: [],
    },
    {
      chainId: 1287,
      address: "0xe75F9ae61926FF1d27d16403C938b4cd15c756d5",
      symbol: "SAT",
      name: "Saturn",
      decimals: 18,
      logoURI: "https://moonbase-minterc20.netlify.app/logos/saturn.svg",
      tags: [],
    },
    {
      chainId: 1287,
      address: "0xd9224c102A73e5941aBfCd645e08623dC4d182bc",
      symbol: "UNS",
      name: "Uranus",
      decimals: 18,
      logoURI: "https://moonbase-minterc20.netlify.app/logos/uranus.svg",
      tags: [],
    },
    {
      chainId: 1287,
      address: "0xed13B028697febd70f34cf9a9E280a8f1E98FD29",
      symbol: "NEPT",
      name: "Neptune",
      decimals: 18,
      logoURI: "https://moonbase-minterc20.netlify.app/logos/neptune.svg",
      tags: [],
    },
    {
      chainId: 1287,
      address: "0x4c945cD20DD13168BC87f30D55f12dC26512ca33",
      symbol: "PLUT",
      name: "Pluto",
      decimals: 18,
      logoURI: "https://moonbase-minterc20.netlify.app/logos/pluto.svg",
      tags: [],
    },
  ],
};
