import { useQuery } from "react-query";
import { getFactoryParams } from "@brainhubinc/api";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { useNetworkInfo } from "./useNetworkInfo";
import { useToken } from "./useToken";

export const useFactoryParams = (id: string) => {
  const { library, chainId, account } = useWeb3React<Web3>();

  const token = useToken(id);
  const networkInfo = useNetworkInfo();

  const erc20TimeAndMaterialFactoryAddress =
    networkInfo?.erc20TimeAndMaterialFactoryAddress || null;

  const erc20factoryParams = useQuery(
    ["factoryParams", chainId, erc20TimeAndMaterialFactoryAddress],
    () =>
      getFactoryParams({
        library,
        timeAndMaterialFactoryAddress: erc20TimeAndMaterialFactoryAddress,
      }),
    {
      enabled: !!(id && library && erc20TimeAndMaterialFactoryAddress),
    },
  );

  const etherTimeAndMaterialFactoryAddress =
    networkInfo?.etherTimeAndMaterialFactoryAddress || null;

  const etherFactoryParams = useQuery(
    ["factoryParams", chainId, etherTimeAndMaterialFactoryAddress],
    () =>
      getFactoryParams({
        library,
        timeAndMaterialFactoryAddress: etherTimeAndMaterialFactoryAddress,
      }),
    {
      enabled: !!(id && library && etherTimeAndMaterialFactoryAddress),
    },
  );

  return token?.data?.token ? erc20factoryParams : etherFactoryParams;
};
