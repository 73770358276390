import BN from "bn.js";

export const toEther = (
  value: number | string | number[] | Uint8Array | Buffer | BN,
  decimals: number,
) => {
  return decimals >= 18
    ? new BN(value, 10).div(new BN(10, 10).pow(new BN(decimals - 18, 10)))
    : new BN(value, 10).mul(new BN(10, 10).pow(new BN(18 - decimals, 10)));
};

export const fromEther = (
  value: number | string | number[] | Uint8Array | Buffer | BN,
  decimals: number,
) => {
  return decimals >= 18
    ? new BN(value, 10).mul(new BN(10, 10).pow(new BN(decimals - 18 || 0, 10)))
    : new BN(value, 10).div(new BN(10, 10).pow(new BN(18 - decimals || 0, 10)));
};
