import React from "react";
import { Flex, Text } from "theme-ui";

export const Logo = ({ title }: { title?: string }) => {
  return (
    <Flex
      sx={{
        alignItems: "center",
      }}
    >
      <svg
        width="24"
        height="22"
        viewBox="0 0 24 22"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.6761 7.54234C19.6439 5.53435 18.7962 3.61885 17.3154 2.20821C15.8346 0.797573 13.8392 0.00459524 11.7587 0H0V8.62546H16.0341C16.5429 8.62691 17.0495 8.69157 17.5412 8.81784C17.2678 10.1052 16.5416 11.2616 15.4857 12.0911C14.4298 12.9206 13.1092 13.3721 11.7478 13.3691H3.814V21.9998H16.0232C17.8067 22.0127 19.5425 21.4439 20.9493 20.3857C22.3562 19.3274 23.3516 17.8416 23.7744 16.169C24.1971 14.4964 24.0224 12.7351 23.2786 11.1703C22.5348 9.60553 21.2654 8.32902 19.6761 7.54761V7.54234ZM2.00938 6.68058V1.95015H11.7478C13.1677 1.95142 14.5397 2.4453 15.6138 3.34174C16.6878 4.23818 17.3922 5.47742 17.5985 6.83343C17.0802 6.73082 16.5523 6.6796 16.0232 6.68058H2.00938ZM20.2058 18.3762C19.6574 18.9075 19.0055 19.3286 18.2876 19.6154C17.5697 19.9022 16.8001 20.0489 16.0232 20.047H5.83431V15.3139H11.7478C13.4888 15.3104 15.1803 14.7543 16.5617 13.7314C17.9431 12.7085 18.9376 11.2755 19.3922 9.65324C20.1013 10.1307 20.6933 10.7525 21.1267 11.4746C21.56 12.1968 21.8241 13.0019 21.9004 13.8332C21.9766 14.6645 21.8632 15.5019 21.5681 16.2863C21.2729 17.0708 20.8033 17.7832 20.1921 18.3736L20.2058 18.3762Z"
          fill="currentColor"
        />
      </svg>

      {title && (
        <Text
          sx={{
            ml: [3, 4],
            fontWeight: "semiBold",
            fontSize: 2,
            color: "inherit",
          }}
        >
          {title}
        </Text>
      )}
    </Flex>
  );
};
