import React from "react";
import { Flex, Image, Text } from "theme-ui";

export const WalletConnectItem = (props: {
  icon: string;
  title: string;
  onClick?: () => void;
}) => {
  const { icon, title, onClick } = props;
  return (
    <Flex
      as="li"
      tabIndex={0}
      role="button"
      aria-pressed="false"
      onClick={onClick}
      onKeyDown={(event) => {
        if (event.key === " " || event.key === "Enter" || event.key === "Spacebar") {
          // "Spacebar" for IE11 support
          // Prevent the default action to stop scrolling when space is pressed
          event.preventDefault();
          onClick && onClick();
        }
      }}
      sx={{
        width: "100%",
        px: 4,
        height: 9,
        alignItems: "center",

        background: "grey100",
        color: "black",
        cursor: "pointer",

        overflow: "hidden",
        borderStyle: "solid",
        borderColor: "grey200",
        borderLeftWidth: "base",
        borderRightWidth: "base",

        "&:first-of-type": {
          borderTopRightRadius: "base",
          borderTopLeftRadius: "base",
          borderTopWidth: "base",
        },

        "&:last-of-type": {
          borderBottomRightRadius: "base",
          borderBottomLeftRadius: "base",
          borderBottomWidth: "base",
        },

        "& + &": {
          borderTopWidth: "base",
        },

        "&:hover, &:focus": {
          background: "linear-gradient(91.34deg, #4D34B6 45.83%, #2E6C9F 100%)",
          color: "white",
          borderColor: "transparent",
        },

        "&:hover + &": {
          borderTopColor: "transparent",
        },

        "&:last-of-type:hover": {
          borderBottomColor: "transparent",
        },
      }}
    >
      <Image
        src={icon}
        variant="avatar"
        sx={{
          background: "white",
          width: 5,
          height: 5,
          borderRadius: "round",
        }}
      />

      <Text
        sx={{
          ml: 3,
          fontSize: 2,
          fontWeight: "bold",
          color: "inherit",
        }}
      >
        {title}
      </Text>
    </Flex>
  );
};
