import { useCallback } from "react";
import { withdrawReward } from "@brainhubinc/api";
import { useMutation, useQueryClient } from "react-query";
import { wait } from "@brainhubinc/commons";
import { toast } from "react-hot-toast";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";

export const useWithdrawRewardMutation = (id: string) => {
  const { library, account, chainId } = useWeb3React<Web3>();
  const queryClient = useQueryClient();

  const withdrawRewardReq = useCallback(
    withdrawReward({
      library,
      account,
      id,
    }),
    [library, account, id],
  );

  return useMutation(withdrawRewardReq, {
    onSuccess: async () => {
      await wait(300);
      await queryClient.invalidateQueries(["alreadyReleasedHours", chainId, , id]);
      await queryClient.invalidateQueries(["availableReward", chainId, id]);
      toast.success(`Withdrawal reward`);
    },
    onError: (e: any) => {
      toast.error(e.message);
    },
  });
};
