import React from "react";
import { Box, Text } from "theme-ui";

export const TabItem = ({
  title,
  active,
  disabled,
}: {
  title: string;
  active?: boolean;
  disabled?: boolean;
}) => {
  return (
    <Box
      sx={{
        paddingY: 2,
        borderBottom: active && "solid 1px #4D34B6",
      }}
    >
      <Text
        sx={{
          fontSize: 1,
          fontWeight: "semiBold",
          color: disabled ? "grey200" : active ? "#4D34B6" : "grey600",
        }}
      >
        {title}
      </Text>
    </Box>
  );
};
