import React from "react";
import { Box, Button, Flex } from "theme-ui";
import { ContractCard } from "../contract-card";
import { TabItem, Tabs } from "../tabs";
import { Link, useParams } from "react-router-dom";
import TimeEntryForm from "../time-entry-form";
import TimeEntriesTable from "../time-entries-table";
import { Role, States } from "@brainhubinc/api";
import { useMetadata } from "../../hooks/useMetadata";
import { useRole } from "../../hooks/useRole";
import { useContractState } from "../../hooks/useContractState";
import { Helmet } from "react-helmet";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";

export const TimeEntries = ({
  id,
  metadata,
  role,
  state,
  networkId,
}: {
  id: string;
  metadata?: {
    title: string;
    description: string;
    rate: number;
    numberOfDays: number;
    hoursPerDay: number;
  };
  role?: Role | null;
  state?: number;
  networkId?: string;
}) => {
  return (
    <Flex
      sx={{
        width: "100%",
        height: "100%",
        flexDirection: "column",
        overflowY: "auto",

        "&.modal-inner-enter": {
          position: "absolute",
          top: 0,
          zIndex: 1,
          transform: "translateX(100%)",
        },
        "&.modal-inner-enter-active": {
          transform: "translateX(0)",
          transition: "transform 400ms",
        },
        "&.modal-inner-exit ": {
          position: "absolute",
          top: 0,
          opacity: 1,
        },
        "&.modal-inner-exit-active": {
          opacity: 0,
          transition: "opacity 350ms ease-in",
        },
      }}
    >
      <Helmet>
        {metadata && <title>{metadata.title} | Time Entries | BrainHub</title>}
      </Helmet>

      <Box
        sx={{
          flex: 1,
        }}
      >
        <ContractCard title={(metadata && metadata.title) || ""}>
          {metadata && (
            <Box>
              <Tabs>
                <Link
                  to={`/contracts/${id}/details`}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <TabItem title="Details" />
                </Link>
                <Box>
                  <TabItem title="Time Entries" active />
                </Box>
              </Tabs>
            </Box>
          )}

          {role === Role.Assignee && state == States.Active && (
            <Box
              sx={{
                marginTop: 4,
              }}
            >
              <TimeEntryForm />
            </Box>
          )}
          <Box
            sx={{
              marginTop: 9,
            }}
          >
            <TimeEntriesTable />
          </Box>

          <Box
            sx={{
              marginTop: 9,
            }}
          >
            <a
              href={`${
                process.env.REPORTS_BASE_URL || "/reports"
              }/report.pdf?network=${networkId}&address=${id}`}
              target={"_blank"}
              style={{
                textDecoration: "none",
              }}
            >
              <Button variant="primary">Download report</Button>
            </a>
          </Box>
        </ContractCard>
      </Box>
    </Flex>
  );
};

const TimeEntriesConnected = () => {
  const { id } = useParams<{ id: string }>();
  const metadata = useMetadata(id);
  const { library, chainId } = useWeb3React<Web3>();
  const role = useRole(id);
  const state = useContractState(id);

  return (
    <TimeEntries
      id={id}
      networkId={(chainId && library?.utils.toHex(chainId)) || undefined}
      metadata={metadata.data}
      role={role.data}
      state={state.data}
    />
  );
};

export default TimeEntriesConnected;
