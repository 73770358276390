import React from "react";
import { Flex } from "theme-ui";

export const WalletConnectList = (props: {
  children: React.ReactElement | React.ReactElement[];
}) => {
  const { children } = props;
  return (
    <Flex
      as="ul"
      sx={{
        minWidth: ["100%", 542],
        width: "100%",
        flexDirection: "column",
      }}
    >
      {children}
    </Flex>
  );
};
