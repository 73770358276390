import React from "react";
import { Flex, Text, ThemeUIStyleObject, Badge } from "theme-ui";
import { Icon, IconVariant } from "../icon";

export const AsideMenuItem = ({
  icon,
  title,
  active,
  future,
  sx,
  onClick,
}: {
  icon: IconVariant;
  title: string;
  active?: boolean;
  future?: boolean;
  minor?: boolean;
  sx?: ThemeUIStyleObject;
  onClick?: () => void;
}) => {
  return (
    <Flex
      onClick={onClick}
      sx={{
        height: 6,
        p: 2,
        alignItems: "center",
        color: future ? "grey600" : "white",
        background: active
          ? "linear-gradient(90.14deg, #4248AE 30.34%, #2E6C9F 100%)"
          : "transparent",
        cursor: "pointer",
        "&:hover": {
          background: !future
            ? "linear-gradient(90.14deg, #4248AE 30.34%, #2E6C9F 100%)"
            : "transparent",
        },
        ...sx,
      }}
    >
      <Icon variant={icon} />
      <Text
        sx={{
          ml: 4,
          fontSize: 1,
          fontWeight: "medium",
          textDecoration: "none",
        }}
      >
        {title}
      </Text>
      {future && (
        <Badge
          variant="accent"
          sx={{
            ml: 2,
          }}
        >
          soon
        </Badge>
      )}
    </Flex>
  );
};
