import React from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Flex, Message, Text } from "theme-ui";

import { TokenInfo } from "@brainhubinc/commons";
import { useTokenInfo } from "src/hooks/useTokenInfo";
import { useAttachDepositMutation } from "src/hooks/useAttachDepositMutation";
import { useBalanceDisplay } from "src/hooks/useBalanceDisplay";
import { useRequiredDepositDisplay } from "src/hooks/useRequiredDepositDisplay";
import { useAssignee } from "src/hooks/useAssignee";
import { useToken } from "src/hooks/useToken";

import { BlockchainAddress, TypesOfAddress } from "../components/BlockchainAddress";
import { BalanceContainer } from "../components/BalanceContainer";
import { ControlContainer } from "../components/ControlContainer";
import { LoadingContent } from "../../loading-content";
import { Icon } from "../../icon";
import { useIsMutating } from "react-query";

type Props = {
  contractAddress?: string;
  assigneeAddress?: string;
  token?: TokenInfo;
  onAttachDeposit?: () => void;
  isLoading?: boolean;
  balanceDisplay?: string;
  requiredDepositDisplay?: string;
};

export const ControlAttachingDeposit = ({
  contractAddress,
  assigneeAddress,
  token,
  onAttachDeposit,
  isLoading,
  balanceDisplay,
  requiredDepositDisplay,
}: Props) => {
  return (
    <ControlContainer>
      <Message
        variant="danger"
        sx={{
          marginBottom: 4,
          alignSelf: "flex-start",
        }}
      >
        <Icon variant="Danger" />
        <Text
          sx={{
            marginLeft: 3,
          }}
        >
          Attach deposit to activate your contract
        </Text>
      </Message>
      <BlockchainAddress address={contractAddress} />
      {assigneeAddress ? (
        <Box
          sx={{
            marginTop: 3,
          }}
        >
          <BlockchainAddress
            address={assigneeAddress}
            typeOfAddress={TypesOfAddress.Assignee}
          />
        </Box>
      ) : null}
      <Box
        sx={{
          marginTop: 6,
        }}
      >
        <Text variant="h3">Deposit</Text>
        <Flex
          sx={{
            marginTop: 3,
            justifyContent: "space-between",
          }}
        >
          <BalanceContainer token={token}>
            <Text>
              {typeof balanceDisplay !== "undefined" ? balanceDisplay : "Unknown"}/
              {typeof requiredDepositDisplay !== "undefined"
                ? requiredDepositDisplay
                : "Unknown"}
            </Text>
          </BalanceContainer>
          <Flex
            sx={{
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                marginLeft: 3,
              }}
            >
              <Button variant="primary" onClick={onAttachDeposit} disabled={isLoading}>
                <LoadingContent isLoading={isLoading}>
                  Attach deposit and start
                </LoadingContent>
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Box>
    </ControlContainer>
  );
};

const ControlAttachingDepositConnected = () => {
  const { id } = useParams<{ id: string }>();
  const token = useToken(id);
  const tokenInfo = useTokenInfo(token?.data?.token || undefined);
  const balanceDisplay = useBalanceDisplay(id);
  const requiredDepositDisplay = useRequiredDepositDisplay(id);
  const attachDepositMutation = useAttachDepositMutation(id);
  const [assigneeAddress] = useAssignee(id);

  const isStartMutating = useIsMutating(["start", id]);

  return (
    <ControlAttachingDeposit
      contractAddress={id}
      assigneeAddress={assigneeAddress}
      token={tokenInfo}
      onAttachDeposit={() => {
        attachDepositMutation.mutate();
        analytics.track("Attaching deposit call", {
          balanceDisplay,
          requiredDepositDisplay,
          id,
        });
      }}
      isLoading={attachDepositMutation.isLoading || isStartMutating > 0}
      balanceDisplay={balanceDisplay}
      requiredDepositDisplay={requiredDepositDisplay}
    />
  );
};

export default ControlAttachingDepositConnected;
