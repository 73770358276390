import { NetworkInfo, TokenInfo } from "./networks/NetworkInfo";

export const getTokens = (networkInfo: NetworkInfo) => {
  return [
    {
      ...networkInfo?.params.nativeCurrency,
      logoURI: networkInfo.icon,
      address: undefined,
    } as TokenInfo,
    ...((networkInfo && networkInfo.tokens) || []),
  ];
};

export const getTokenInfo = (
  token: string | undefined,
  networkInfo: NetworkInfo,
): TokenInfo | undefined => {
  const tokens = (networkInfo && networkInfo.tokens) || [];
  return !token
    ? {
        ...networkInfo?.params.nativeCurrency,
        logoURI: networkInfo.icon,
        address: undefined,
      }
    : tokens.find((item: any) => token.toLowerCase() == item.address.toLowerCase());
};
