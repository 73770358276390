import { useMemo } from "react";
import { toEther } from "@brainhubinc/commons";
import { useTokenInfo } from "../hooks/useTokenInfo";
import { useToken } from "./useToken";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { useAvailableRemainingDeposit } from "./useAvailableRemainingDeposit";

export const useAvailableRemainingDepositDisplay = (id: string) => {
  const { library } = useWeb3React<Web3>();
  const availableRemainingDeposit = useAvailableRemainingDeposit(id);
  const token = useToken(id);
  const tokenInfo = useTokenInfo(token?.data?.token || undefined);

  return useMemo(
    () =>
      availableRemainingDeposit.data &&
      tokenInfo &&
      library?.utils.fromWei(
        toEther(availableRemainingDeposit.data, tokenInfo.decimals),
      ),
    [availableRemainingDeposit.data, tokenInfo],
  );
};
