import Web3 from "web3";
import * as TimeAndMaterialFactoryABi from "@brainhubinc/commons/src/contracts/TimeAndMaterialFactory.json";

export const getFactoryParams = async ({
  library,
  timeAndMaterialFactoryAddress,
}: {
  library?: Web3;
  timeAndMaterialFactoryAddress?: string | null;
}) => {
  if (!timeAndMaterialFactoryAddress) {
    throw new Error("Need Factory to get");
  }

  const TimeAndMaterialFactoryContract = new (library as Web3).eth.Contract(
    TimeAndMaterialFactoryABi.abi as any,
    timeAndMaterialFactoryAddress,
  );

  const [feePercentage, feeDecimals] = await Promise.all([
    TimeAndMaterialFactoryContract.methods.feePercentage().call(),
    TimeAndMaterialFactoryContract.methods.feeDecimals().call(),
  ]);

  return {
    feePercentage,
    feeDecimals,
    feePercentageNormalized: feePercentage / 10 ** feeDecimals,
  };
};
