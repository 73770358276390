import { Box, Flex, Text, Image } from "theme-ui";
import { ThemeUIStyleObject } from "@theme-ui/css";
import "react-select/dist/react-select.css";
import "react-virtualized-select/styles.css";

import Select from "react-virtualized-select";
import { useTokenInfo } from "../../hooks/useTokenInfo";
import { useTokens } from "../../hooks/useTokens";
import React from "react";

const VirtualizedSelect: any = Select;

interface TokenInfo {
  chainId?: number;
  address?: string | undefined;
  symbol: string;
  name: string;
  decimals: number;
  logoURI?: string;
  tags?: string[];
  balance?: number;
}

export const TokensSelect = ({
  sx,
  items,
  onSelect,
  selectedItem,
}: {
  sx?: ThemeUIStyleObject;
  selectedItem: TokenInfo | undefined;
  items: TokenInfo[];
  onSelect?: (args: TokenInfo) => void;
}) => {
  return (
    <Box
      sx={{
        "& .Select-control": {
          height: 6,
          background: "grey100",
          borderRadius: "base",
          borderColor: "grey100",
        },
        "& .Select-control:hover": {
          boxShadow: "none",
        },
        "& .Select-placeholder": {
          lineHeight: "40px",
          paddingX: 3,
        },
        "& .Select-input": {
          height: 6,
          paddingX: 3,
        },
        "& .Select-menu-outer": {
          border: "none",
          boxShadow: "none",
          borderBottomRightRadius: "base",
          borderBottomLeftRadius: "base",
          overflow: "hidden",
        },
        "& .is-open > .Select-control": {
          background: "grey100",
          borderColor: "grey100",
        },
        "& .Select-arrow": {
          border: "none",
          width: 4,
          height: 4,
          background: "grey600",
          mask: `url("data:image/svg+xml;utf8,<svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M18 9L12 15L6 9' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>")`,
        },
        "& .is-open .Select-arrow-zone": {
          paddingTop: "4px",
        },
        "& .Select-input > input": {
          padding: 0,
          height: 6,
        },
        "& .Select-control > :last-child": {
          paddingRight: 3,
        },
        "& .Select-placeholder, .Select--single > .Select-control .Select-value": {
          paddingX: 3,
        },
        "& .Select.is-focused:not(.is-open) > .Select-control": {
          borderColor: "grey100",
          boxShadow: "none",
          background: "grey100",
        },
        ...sx,
      }}
    >
      <VirtualizedSelect
        clearable={false}
        options={items}
        filterOption={(option: any, input: string) => {
          const selectedItem = option as TokenInfo;
          return (
            selectedItem?.name?.toLowerCase().includes(input.toLowerCase()) ||
            selectedItem?.symbol?.toLowerCase().includes(input.toLowerCase())
          );
        }}
        onChange={(data: any) => {
          onSelect && onSelect(data);
        }}
        optionHeight={40}
        value={selectedItem || undefined}
        valueKey="address"
        valueRenderer={(option: any) => {
          const selectedItem = option as TokenInfo;
          return (
            <Flex
              sx={{
                alignItems: "center",
                height: 6,
              }}
            >
              {selectedItem && (
                <Image
                  src={selectedItem.logoURI}
                  sx={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              )}
              <Text
                sx={{
                  fontWeight: "bold",
                  fontSize: 1,
                  flex: 1,
                  textAlign: "start",
                  marginLeft: 3,
                }}
              >
                {selectedItem ? selectedItem.name : ""}
              </Text>
            </Flex>
          );
        }}
        optionRenderer={({
          option,
          style,
          focusedOption,
          focusOption,
          selectValue,
        }: any) => {
          const item = option as TokenInfo;
          return (
            <Flex
              style={style}
              sx={{
                paddingX: 3,
                height: 6,
                alignItems: "center",
                background:
                  focusedOption === option
                    ? "linear-gradient(93.1deg, #4D34B6 0%, #2E6C9F 100%)"
                    : "grey100",
                color: focusedOption === option ? "white" : "black",
                width: "100%",
              }}
              onMouseEnter={() => focusOption(option)}
              onClick={() => selectValue(option)}
            >
              {item.logoURI && (
                <Image
                  src={item.logoURI}
                  sx={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              )}
              <Text
                sx={{
                  marginLeft: 3,
                  fontWeight: "bold",
                  fontSize: 1,
                  flex: 0.5,
                  textAlign: "start",
                }}
              >
                {item.name}
              </Text>
            </Flex>
          );
        }}
      />
    </Box>
  );
};

const TokensSelectConnected = ({
  value,
  ...otherProps
}: {
  sx?: ThemeUIStyleObject;
  value: string | null;
  onSelect?: (args: TokenInfo) => void;
}) => {
  const selectedItem = useTokenInfo(value || undefined);

  const tokens = useTokens();

  return (
    <TokensSelect selectedItem={selectedItem} items={tokens || []} {...otherProps} />
  );
};

export default TokensSelectConnected;
