import { matchPath, useHistory, useLocation } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { connectors } from "src/helpers/connectors";

export const useRedirectToAuthPage = (authPagePath: string) => {
  const history = useHistory();
  const location = useLocation();
  const { active, activate } = useWeb3React();
  const [previousLocation, setPreviousLocation] = useState<any>();

  useEffect(() => {
    if (active) {
      return;
    }
    setPreviousLocation(location);
    const goToAuth = () => history.push(authPagePath, { replace: true });
    const provider = window.localStorage.getItem("provider");

    if (provider) {
      // @ts-ignore
      activate(connectors[provider], () => {
        console.log("error");
        goToAuth();
      });
    } else {
      goToAuth();
    }
  }, [active]);

  useEffect(() => {
    if (active && previousLocation) {
      const isAuth = matchPath(previousLocation.pathname, {
        path: authPagePath,
        exact: true,
        strict: false,
      });
      history.push(isAuth ? "/" : previousLocation.pathname);
    }
  }, [active, previousLocation]);
};
