export const toISOIgnoreTimezone = (inputDate: Date) => {
  return new Date(
    inputDate.getFullYear() +
      "-" +
      ("0" + (inputDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + inputDate.getDate()).slice(-2) +
      "T00:00:00.000Z",
  );
};

export const toSecondsTimestamp = (inputDate: Date) => {
  return Math.floor((inputDate?.getTime() || 0) / 1000);
};

export const fromSecondsTimestamp = (timestamp: number) => {
  return new Date(timestamp * 1000);
};

export const toSolidity = (inputDate: Date) => {
  return toSecondsTimestamp(toISOIgnoreTimezone(inputDate));
};

export const fromSolidity = (timestamp: number | string) => {
  return fromSecondsTimestamp(
    typeof timestamp === "string" ? parseInt(timestamp) : timestamp,
  );
};
