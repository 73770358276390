import Web3 from "web3";
import { NetworkKey, networks } from "@brainhubinc/commons";

export const switchNetwork = async (library: Web3, chainId: NetworkKey) => {
  if (
    !library.currentProvider ||
    typeof library.currentProvider === "string" ||
    !("request" in library.currentProvider) ||
    !library.currentProvider.request
  ) {
    throw new Error("Empty provider");
  }
  try {
    await library.currentProvider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: chainId }],
    });
  } catch (switchError: any) {
    if (switchError.code === 4902) {
      await library.currentProvider.request({
        method: "wallet_addEthereumChain",
        params: [(networks[chainId] as any).params],
      });
    }
  }
};
