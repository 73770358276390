import { AbstractProvider } from "web3-core";
import { connectors } from "./connectors";

declare global {
  interface Window {
    ethereum?: AbstractProvider;
    tronWeb?: any;
  }
}

export const wallets = [
  {
    icon: require("./wallets-icons/Metamask.png"),
    title: "MetaMask",
    connector: connectors.injected,
    key: "injected",
  },
  {
    icon: require("./wallets-icons/Coinbase.png"),
    title: "Coinbase Wallet",
    connector: connectors.coinbaseWallet,
    key: "coinbaseWallet",
  },
  {
    icon: require("./wallets-icons/WalletConnect.png"),
    title: "Wallet Connect",
    connector: connectors.walletConnect,
    key: "walletConnect",
  },
];
