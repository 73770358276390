import React from "react";
import { Box, Flex, Text } from "theme-ui";
import { AttentionIcon } from "../attention-icon";

export const ContractsListItem = ({
  title,
  subTitle,
  stopped,
  active,
  warning,
}: {
  title: string;
  subTitle: string;
  stopped?: boolean;
  active?: boolean;
  warning?: boolean;
}) => {
  return (
    <Flex
      sx={{
        px: 5,
        py: 3,
        borderBottom: "solid 1px #E6E8EC",
        alignItems: "center",
        ".selected  &": {
          background: "linear-gradient(93.1deg, #4D34B6 0%, #2E6C9F 100%)",
        },
      }}
    >
      <Flex
        sx={{
          flexDirection: "column",
          flex: 1,
        }}
      >
        <Text
          sx={{
            fontSize: 2,
            fontWeight: "medium",
            lineHeight: "heading",
            color: stopped ? "grey600" : "black",
            ".selected  &": {
              color: "white",
            },
          }}
        >
          {title}
        </Text>
        <Text
          sx={{
            fontSize: 1,
            lineHeight: "medium",
            color: "grey600",
            ".selected  &": {
              color: "white",
            },
            mt: 1,
          }}
        >
          {subTitle}
        </Text>
      </Flex>

      {warning && <AttentionIcon />}
    </Flex>
  );
};
