import React from "react";
import { Flex, Box, InputProps, Input, Text } from "theme-ui";
import { FieldError } from "react-hook-form";
import { GradientBorderContainer } from "./GradientBorderContainer";
import { Hint } from "./Hint";
import { Error } from "./Error";

interface CustomInputProps extends InputProps {
  hint?: string;
  error?: FieldError;
}

export const CustomInput = ({ hint, sx, error, ...otherProps }: CustomInputProps) => {
  return (
    <Box
      sx={{
        ...sx,
      }}
    >
      <GradientBorderContainer error={error}>
        <Flex
          sx={{
            paddingY: 2,
            paddingX: 3,
            background: "grey100",
            alignItems: "center",
            borderRadius: "5px",
          }}
        >
          <Input
            {...otherProps}
            sx={{
              fontFamily: "inherit",
              fontWeight: "body",
              border: "none",
              fontSize: 2,
              color: "black",
              lineHeight: "body",
              marginBottom: 0,
              padding: 0,
              ":focus-visible": {
                outline: "none",
              },
            }}
          />

          {hint && <Hint title={hint} />}
        </Flex>
      </GradientBorderContainer>
      {error && <Error error={error} />}
    </Box>
  );
};
