import { useMemo } from "react";
import { toEther } from "@brainhubinc/commons";
import { useToken } from "./useToken";
import { useTokenInfo } from "../hooks/useTokenInfo";
import { useMetadata } from "./useMetadata";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { useAvailableReward } from "./useAvailableReward";

export const useAvailableRewardDisplay = (id: string) => {
  const { library } = useWeb3React<Web3>();
  const token = useToken(id);
  const tokenInfo = useTokenInfo(token?.data?.token || undefined);
  const metadata = useMetadata(id);
  const availableReward = useAvailableReward(id);

  return useMemo(
    () =>
      tokenInfo && metadata.data && typeof availableReward.data !== "undefined"
        ? library?.utils.fromWei(toEther(availableReward.data, tokenInfo.decimals))
        : undefined,
    [metadata.data, availableReward.data, tokenInfo],
  );
};
