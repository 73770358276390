import { useQuery } from "react-query";
import { getAvailableFee } from "@brainhubinc/api";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";

export const useAvailableFee = (id: string) => {
  const { library, chainId } = useWeb3React<Web3>();

  return useQuery(["availableFee", chainId, id], getAvailableFee({ library, id }), {
    enabled: !!(library && id),
  });
};
