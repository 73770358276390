import { useMemo } from "react";
import { toEther } from "@brainhubinc/commons";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { useToken } from "./useToken";
import { useTokenInfo } from "./useTokenInfo";
import { useMetadata } from "./useMetadata";

export const useRateDisplay = (id: string) => {
  const { library } = useWeb3React<Web3>();
  const token = useToken(id);
  const tokenInfo = useTokenInfo(token?.data?.token || undefined);
  const metadata = useMetadata(id);

  return useMemo(
    () =>
      tokenInfo &&
      metadata.data &&
      library?.utils.fromWei(toEther(metadata.data.rate, tokenInfo.decimals)),
    [tokenInfo, metadata],
  );
};
