import React from "react";
import DatePicker from "react-datepicker";
import { FieldError } from "react-hook-form";

import "react-datepicker/dist/react-datepicker.css";
import { Box } from "theme-ui";
import { ThemeUIStyleObject } from "@theme-ui/css";
import { Error } from "../custom-form/Error";
import { GradientBorderContainer } from "../custom-form/GradientBorderContainer";

export const CustomDatePicker = ({
  sx,
  selected,
  onChange,
  error,
}: {
  sx?: ThemeUIStyleObject;
  selected?: Date | null;
  onChange: (date: Date | null) => void;
  error?: FieldError;
}) => {
  return (
    <Box
      sx={{
        ...(sx || {}),
      }}
    >
      <GradientBorderContainer error={error}>
        <Box
          sx={{
            ".react-datepicker__input-container > input": {
              paddingY: 2,
              paddingX: 3,
              background: "grey100",
              alignItems: "center",
              borderRadius: "5px",
              fontFamily: "inherit",
              fontWeight: "body",
              border: "none",
              fontSize: 2,
              color: "black",
              lineHeight: "body",
              width: "100%",
              boxSizing: "border-box",
              ":focus-visible": {
                outline: "none",
              },
            },
            ".react-datepicker__header": {
              background: "white",
              borderBottom: "none",
              padding: 0,
            },
            ".react-datepicker": {
              fontFamily: "body",
              border: "none",
              boxShadow:
                "-1px 1px 2px rgba(240, 240, 240, 0.2), 1px -1px 2px rgba(240, 240, 240, 0.2), -1px -1px 2px rgba(255, 255, 255, 0.9), 1px 1px 3px rgba(240, 240, 240, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(240, 240, 240, 0.5);",
              borderRadius: "base",
              paddingX: 3,
              paddingY: 4,
            },
            ".react-datepicker__triangle": {
              display: "none",
            },
            ".react-datepicker__day--keyboard-selected": {
              backgroundColor: "#4D34B6",
            },
            ".react-datepicker__day--selected": {
              background: "linear-gradient(91.34deg, #4D34B6 45.83%, #2E6C9F 100%)",
            },
            ".react-datepicker__month": {
              padding: 0,
              margin: 0,
            },
            ".react-datepicker__day-names": {
              marginBottom: 0,
            },
            ".react-datepicker__week": {
              borderTopColor: "grey200",
              borderTopWidth: "base",
              borderTopStyle: "solid",
            },
            ".react-datepicker__day-name": {
              margin: 2,
              width: "30px",
              fontSize: 0,
              color: "grey600",
              fontWeight: "medium",
              lineHeight: "20px",
            },
            ".react-datepicker__day": {
              padding: 1,
              margin: 1,
              width: "30px",
              fontSize: 1,
              lineHeight: "20px",
              fontWeight: "body",
            },
            ".react-datepicker__current-month": {
              fontSize: 4,
              lineHeight: "medium",
              textAlign: "left",
            },
            ".react-datepicker__navigation--next": {
              right: 3,
              top: 4,
            },
            ".react-datepicker__navigation--previous": {
              left: "auto",
              right: "72px",
              top: 4,
            },
            ".react-datepicker__navigation-icon": {
              position: "static",
              width: 4,
              height: 4,
              background: `url("data:image/svg+xml;utf8,<svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M18 9L12 15L6 9' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>")`,
            },
            ".react-datepicker__navigation-icon:before": {
              content: "none",
            },
            ".react-datepicker__navigation": {
              width: "auto",
              height: "auto",
              padding: 2,
            },
            ".react-datepicker__navigation-icon--next": {
              transform: "rotate(-90deg)",
            },
            ".react-datepicker__navigation-icon--previous": {
              transform: "rotate(90deg)",
            },
            '.react-datepicker-popper[data-placement^="bottom"]': {
              paddingTop: 2,
            },
            '.react-datepicker-popper[data-placement^="top"]': {
              paddingBottom: 2,
            },
          }}
        >
          <DatePicker selected={selected} onChange={onChange} />
        </Box>
      </GradientBorderContainer>
      {error && <Error error={error} />}
    </Box>
  );
};
