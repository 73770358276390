import React from "react";
import { Box, Flex } from "theme-ui";
import { ContractsListHeader } from "./ContractsListHeader";
import { ContractsListSubHeader } from "./ContractsListSubHeader";
import { ContractsListItem } from "./ContractsListItem";
import { useQuery } from "react-query";
import { getOffers, OffersData, States } from "@brainhubinc/api";
import { NavLink } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";

export const ContractsList = ({
  actives,
  drafts,
  stopped,
}: {
  actives: OffersData;
  drafts: OffersData;
  stopped: OffersData;
}) => {
  return (
    <Flex
      sx={{
        width: "430px",
        flexShrink: 0,
        flexDirection: "column",
        background: "grey100",
      }}
    >
      <ContractsListHeader />
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
        }}
      >
        {actives.length > 0 && (
          <>
            <ContractsListSubHeader title="Current" />
            {actives.map((offer) => {
              return (
                <NavLink
                  to={`/contracts/${offer.contractAddress}`}
                  style={{
                    textDecoration: "none",
                  }}
                  activeClassName="selected"
                  key={offer.id}
                >
                  <ContractsListItem
                    title={offer.title}
                    subTitle={`${offer.numberOfDays} days - ${offer.hoursPerDay}h/day`}
                    warning={!offer.state}
                  />
                </NavLink>
              );
            })}
          </>
        )}
        {stopped.length > 0 && (
          <>
            <ContractsListSubHeader title="Finished" />
            {stopped.map((offer) => {
              return (
                <NavLink
                  to={`/contracts/${offer.contractAddress}`}
                  style={{
                    textDecoration: "none",
                  }}
                  activeClassName="selected"
                  key={offer.id}
                >
                  <ContractsListItem
                    title={offer.title}
                    subTitle={`${offer.numberOfDays} days - ${offer.hoursPerDay}h/day`}
                    stopped
                  />
                </NavLink>
              );
            })}
          </>
        )}
        {drafts.length > 0 && (
          <>
            <ContractsListSubHeader title="Drafts" />
            {drafts.map((offer) => {
              return (
                <NavLink
                  to={`/contracts/draft/${offer.id}`}
                  style={{
                    textDecoration: "none",
                  }}
                  activeClassName="selected"
                  key={offer.id}
                >
                  <ContractsListItem
                    title={offer.title}
                    subTitle={`${offer.numberOfDays} days - ${offer.hoursPerDay}h/day`}
                  />
                </NavLink>
              );
            })}
          </>
        )}
      </Box>
    </Flex>
  );
};

const ContractsListConnected = () => {
  const { account } = useWeb3React<Web3>();
  const queryByClient = useQuery(
    ["offers", "byClient"],
    () => getOffers({ client: account || undefined }),
    {
      enabled: !!account,
    },
  );

  const queryByAssignee = useQuery(
    ["offers", "byAssignee"],
    () => getOffers({ assignee: account || undefined }),
    {
      enabled: !!account,
    },
  );

  const drafts = (queryByClient.data || []).filter((item) => !item.contractAddress);
  const launched = [
    ...(queryByAssignee.data || []),
    ...(queryByClient.data || []),
  ].filter((item) => !!item.contractAddress);

  const actives = launched.filter((item) => {
    return item.state != States.Stopped;
  });

  const stopped = launched.filter((item) => {
    return item.state == States.Stopped;
  });

  return <ContractsList actives={actives} stopped={stopped} drafts={drafts} />;
};

export default ContractsListConnected;
