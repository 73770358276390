import { useQuery } from "react-query";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";

import { getClient } from "@brainhubinc/api";

export const useClient = (id: string) => {
  const { library, chainId } = useWeb3React<Web3>();
  const { data } = useQuery(
    ["contract", "client", chainId, id],
    getClient({ library, id }),
    {
      enabled: !!(id && library),
    },
  );

  return [data];
};
