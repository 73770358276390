import React from "react";
import { Box, Flex, Text } from "theme-ui";
import { Icon } from "../../../icon";

export const CompletedItem = ({ title }: { title: string }) => {
  return (
    <Flex
      sx={{
        alignItems: "center",
        "* + &": {
          position: "relative",
          marginTop: 4,
          ":before": {
            content: '""',
            position: "absolute",
            width: "1px",
            height: "16px",
            background: "linear-gradient(91.34deg, #4D34B6 45.83%, #2E6C9F 100%)",
            top: "-20px",
            left: "11.5px",
          },
        },
      }}
    >
      <Flex
        sx={{
          alignItems: "center",
          justifyItems: "center",
          borderRadius: "round",
          background: "linear-gradient(91.34deg, #4D34B6 45.83%, #2E6C9F 100%)",
          color: "white",
        }}
      >
        <Icon variant={"Check"} />
      </Flex>

      <Text
        sx={{
          marginLeft: 3,
          fontSize: 2,
          lineHeight: "body",
        }}
      >
        {title}
      </Text>
    </Flex>
  );
};
