import React from "react";
import { Box, Text } from "theme-ui";
import { ThemeUIStyleObject } from "@theme-ui/css";

export const Table = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      as={"table"}
      sx={{
        width: "100%",
      }}
    >
      {children}
    </Box>
  );
};

export const TableHeader = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      as="tr"
      sx={{
        borderBottom: "solid 1px #4D34B6",
        width: "100%",
        paddingX: 3,
      }}
    >
      {children}
    </Box>
  );
};

export const TableHeaderItem = ({
  title,
  sx = {},
}: {
  title: string;
  sx?: ThemeUIStyleObject;
}) => {
  return (
    <Box
      as="th"
      sx={{
        paddingY: 2,
        paddingX: 3,
        textAlign: "left",
        marginBottom: 4,
        whiteSpace: "nowrap",
        fontSize: 1,
        fontWeight: "bold",
        lineHeight: "20px",
        ...sx,
      }}
    >
      <Text>{title}</Text>
    </Box>
  );
};

export const TableRow = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      as="tr"
      sx={{
        "& + &": {
          borderTopColor: "grey200",
          borderTopWidth: "base",
          borderTopStyle: "solid",
        },
        width: "100%",
        paddingX: 3,
      }}
    >
      {children}
    </Box>
  );
};

export const TableCell = ({
  text,
  children,
  sx = {},
}: {
  text?: string;
  children?: React.ReactNode;
  sx?: ThemeUIStyleObject;
}) => {
  return (
    <Box
      as="th"
      sx={{
        paddingY: 2,
        paddingX: 3,
        textAlign: "left",
        whiteSpace: "nowrap",
        fontSize: 1,
        lineHeight: "20px",
        ...sx,
      }}
    >
      {text && <Text>{text}</Text>}
      {children}
    </Box>
  );
};

export const TableSpacer = () => {
  return (
    <tr>
      <Box
        as="td"
        sx={{
          height: 3,
        }}
      />
    </tr>
  );
};
