import { Modal } from "./modal";
import { NetworkKey, networks } from "@brainhubinc/commons";
import { NetworkModalItem } from "./item";
import React from "react";
import { Box, Text } from "theme-ui";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { switchNetwork } from "../../helpers/switchNetwork";
import { toast } from "react-hot-toast";

export const NetworkModal = ({
  isOpen,
  chainIdHex,
  onClose,
  onClick,
}: {
  isOpen: boolean;
  chainIdHex: string | undefined;
  onClose?: () => void;
  onClick?: (networkKey: NetworkKey) => void;
}) => {
  return (
    <TransitionGroup>
      <CSSTransition
        key={isOpen ? "true" : "false"}
        classNames="modal-inner"
        timeout={{
          enter: 300,
          exit: 400,
        }}
      >
        {isOpen ? (
          <Modal onClose={onClose}>
            <Box
              sx={{
                padding: 5,
              }}
            >
              <Box>
                <Text
                  sx={{
                    fontSize: 2,
                    fontWeight: "medium",
                    lineHeight: "20px",
                    color: "black",
                  }}
                >
                  Change network
                </Text>
              </Box>
              <Box
                sx={{
                  paddingTop: 5,
                  paddingX: 4,
                }}
              >
                {Object.keys(networks).map((networkId) => {
                  return (
                    <Box
                      key={networkId}
                      sx={{
                        "& + &": {
                          marginTop: 2,
                        },
                      }}
                    >
                      <NetworkModalItem
                        onClick={() => onClick && onClick(networkId as NetworkKey)}
                        active={networkId === chainIdHex}
                        networkInfo={networks[networkId as NetworkKey]}
                      />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Modal>
        ) : (
          <Box />
        )}
      </CSSTransition>
    </TransitionGroup>
  );
};

const NetworkModalConnected = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose?: () => void;
}) => {
  const { library, chainId } = useWeb3React<Web3>();

  return (
    <NetworkModal
      isOpen={isOpen}
      onClose={onClose}
      onClick={async (key) => {
        if (!library) {
          return;
        }
        try {
          await switchNetwork(library, key);
        } catch (e: any) {
          toast.error(e.message);
        }
        onClose && onClose();
      }}
      chainIdHex={library && chainId ? library.utils.toHex(chainId) : undefined}
    />
  );
};

export default NetworkModalConnected;
