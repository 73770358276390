import { MetadataData, OfferData, Role, States, TimeEntry } from "./contracts";
import { API_HOST } from "../common";
import Web3 from "web3";
import BN from "bn.js";
import {
  fromSolidity,
  fromEther,
  getTokenInfo,
  NetworkInfo,
  onReceipt,
  TimeAndMaterialABi,
  ERC20TimeAndMaterialABi,
  ERC20ABi,
  ERC20TimeAndMaterialFactoryABi,
  EtherTimeAndMaterialFactoryABi,
} from "@brainhubinc/commons";
import { stringify } from "query-string";

export const getOffer = async (id: string) => {
  const url = `${API_HOST}/offchain/api/offers/${id}`;
  const req = await fetch(url);
  const data = await req.json();

  if (!req.ok) {
    throw new Error(data);
  }
  return data as OfferData;
};

export const getOffers = async ({
  client,
  assignee,
  contractAddress,
}: {
  client?: string;
  assignee?: string;
  contractAddress?: string;
}) => {
  const url = `${API_HOST}/offchain/api/offers?${stringify({
    "client.equals": client,
    "assignee.equals": assignee,
    "contractAddress.equals": contractAddress,
  })}`;
  const req = await fetch(url);
  const data = await req.json();

  if (!req.ok) {
    throw new Error(data);
  }
  return data as OfferData[];
};

export const postOffers = async (offer: Partial<OfferData>) => {
  const url = `${API_HOST}/offchain/api/offers`;
  const req = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      ...offer,
      startOffer: offer.startOffer && fromSolidity(offer.startOffer).toISOString(),
      state: offer.state || States.NotStarted,
    }),
    headers: {
      "Content-Type": "application/json",
      accept: "*/*",
    },
  });
  const data = await req.json();

  if (!req.ok) {
    throw new Error(data);
  }
  return data as OfferData;
};

export const patchOffers = async ({
  id,
  offer,
}: {
  id: string | number;
  offer: Partial<OfferData>;
}) => {
  const url = `${API_HOST}/offchain/api/offers/${id}`;
  const req = await fetch(url, {
    method: "PATCH",
    body: JSON.stringify({
      ...offer,
      startOffer: offer.startOffer && fromSolidity(offer.startOffer).toISOString(),
    }),
    headers: {
      "Content-Type": "application/json",
      accept: "*/*",
    },
  });
  const data = await req.json();

  if (!req.ok) {
    throw new Error(data);
  }
  return data as OfferData;
};

export const deleteOffer = async ({ id }: { id: string | number }) => {
  const url = `${API_HOST}/offchain/api/offers/${id}`;
  const req = await fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      accept: "*/*",
    },
  });

  if (!req.ok) {
    throw new Error("Is not OK");
  }
};

export const saveOffer = async (offer: Partial<OfferData>) => {
  if (!offer?.id) {
    return await postOffers(offer);
  } else {
    return await patchOffers({ id: offer.id, offer });
  }
};

export const startOffer =
  ({ onSaveOffer }: { onSaveOffer?: (offer?: OfferData) => void }) =>
  async ({
    offer,
    library,
    account,
    erc20TimeAndMaterialFactoryAddress,
    etherTimeAndMaterialFactoryAddress,
    networkInfo,
    chainId,
  }: {
    offer: OfferData;
    library?: Web3;
    account?: string | null;
    erc20TimeAndMaterialFactoryAddress?: string | null;
    etherTimeAndMaterialFactoryAddress?: string | null;
    networkInfo?: NetworkInfo;
    chainId?: string;
  }) => {
    const savedOffer = await saveOffer(offer);
    onSaveOffer && onSaveOffer(savedOffer);
    const { assignee, startOffer, token, description, ...metadata } = savedOffer;
    if (!networkInfo) {
      throw new Error("Need Network to start");
    }
    if (!assignee || !account) {
      throw new Error("Need assignee and signer to start");
    }
    if (!erc20TimeAndMaterialFactoryAddress || !etherTimeAndMaterialFactoryAddress) {
      throw new Error("Need Factory to start");
    }

    const ERC20TimeAndMaterialFactoryContract = new (library as Web3).eth.Contract(
      ERC20TimeAndMaterialFactoryABi.abi as any,
      erc20TimeAndMaterialFactoryAddress,
    );

    const EtherTimeAndMaterialFactoryContract = new (library as Web3).eth.Contract(
      EtherTimeAndMaterialFactoryABi.abi as any,
      etherTimeAndMaterialFactoryAddress,
    );

    const rateInWei = library?.utils.toWei("" + metadata.rate, "ether");
    let tokenInfo = getTokenInfo(token || undefined, networkInfo);
    if (!tokenInfo) {
      // Fallback to native currency
      // As an example, a draft could be created in other chain and saved token address does not work in current chain
      tokenInfo = getTokenInfo(undefined, networkInfo);
    }
    if (!tokenInfo) {
      throw new Error("Don't have token");
    }
    const rate =
      tokenInfo && rateInWei && fromEther(rateInWei, tokenInfo.decimals).toString(10);

    const metadataForContract = {
      ...metadata,
      description: description || "",
      startDate: startOffer,
      rate,
    };

    const result = await onReceipt(
      tokenInfo.address
        ? ERC20TimeAndMaterialFactoryContract.methods
            .createContract(tokenInfo.address, account, assignee, metadataForContract)
            .send({
              from: account,
            })
        : EtherTimeAndMaterialFactoryContract.methods
            .createContract(account, assignee, metadataForContract)
            .send({
              from: account,
            }),
    );

    const address = result.events.ContractCreated.returnValues.newContract as string;

    const updatedOffer = await saveOffer({
      ...savedOffer,
      contractAddress: address,
      chainId,
    });

    await waitForContract({ library, id: address });
    return { address, id: updatedOffer.id };
  };

export const calculateRequiredDeposit = ({
  metadata,
  feePercentage,
  feeDecimals,
}: {
  metadata: MetadataData;
  feePercentage: number;
  feeDecimals: number;
}) => {
  const deposit = new BN(metadata.rate, 10)
    .mul(new BN(metadata.numberOfDays, 10))
    .mul(new BN(metadata.hoursPerDay, 10));
  const fee = deposit
    .mul(new BN(feePercentage, 10))
    .div(new BN(10, 10).pow(new BN(feeDecimals, 10)));
  return deposit.add(fee);
};

export const attachDeposit =
  ({
    library,
    metadata,
    requiredDeposit,
    account,
    depositAddress,
    token,
  }: {
    library?: Web3;
    metadata?: MetadataData;
    requiredDeposit?: BN;
    account?: string | null;
    depositAddress?: string;
    token: string | undefined;
  }) =>
  () => {
    if (!library || !metadata || !requiredDeposit || !account) {
      throw new Error("Can't load data");
    }
    if (token) {
      const contract = new library.eth.Contract(ERC20ABi as any, token);
      return contract.methods
        .transfer(depositAddress, requiredDeposit.toString(10))
        .send({
          from: account,
        });
    } else {
      return library.eth.sendTransaction({
        from: account || undefined,
        to: depositAddress,
        value: requiredDeposit.toString(10),
      });
    }
  };

export const waitForContract = ({ library, id }: { library?: Web3; id?: string }) => {
  if (!library || !id) {
    throw new Error("Need connection and id");
  }
  return new Promise((resolve) => {
    const check = async () => {
      const contract = new library.eth.Contract(TimeAndMaterialABi.abi as any, id);
      await contract.methods.metadata().call();
    };
    const interval = setInterval(() => {
      check()
        .then(() => {
          clearInterval(interval);
          resolve(true);
        })
        .catch(console.log);
    }, 1000);
  });
};

export const getMetadata =
  ({ library, id }: { library?: Web3; id?: string }) =>
  async () => {
    if (!library || !id) {
      throw new Error("Need connection and id");
    }
    const contract = new library.eth.Contract(TimeAndMaterialABi.abi as any, id);
    const data = await contract.methods.metadata().call();
    // Can't use validator :(
    return data as MetadataData;
  };

export const getState =
  ({ library, id }: { library?: Web3; id?: string }) =>
  async () => {
    if (!library || !id) {
      throw new Error("Need connection and id");
    }
    const contract = new library.eth.Contract(TimeAndMaterialABi.abi as any, id);
    const data = await contract.methods.state().call();
    return data as number;
  };

export const getOfferByContractAddress = async (contractAddress: string) => {
  const offers = await getOffers({ contractAddress });
  if (offers.length === 0) {
    throw new Error("Not found");
  }
  return offers[0];
};

export const startContract =
  ({
    library,
    id,
    account,
  }: {
    library?: Web3;
    id?: string;
    account?: string | null;
  }) =>
  async () => {
    if (!library || !id) {
      throw new Error("Need connection and id");
    }
    const contract = new library.eth.Contract(TimeAndMaterialABi.abi as any, id);
    await contract.methods.start().send({
      from: account,
    });

    const offer = await getOfferByContractAddress(id);
    await saveOffer({
      ...offer,
      state: States.Active,
    });
  };

export const restartContract =
  ({
    library,
    id,
    account,
  }: {
    library?: Web3;
    id?: string;
    account?: string | null;
  }) =>
  async ({
    startDate,
    numberOfDays,
    hoursPerDay,
    rate,
  }: {
    startDate: number;
    numberOfDays: number;
    hoursPerDay: number;
    rate: BN;
  }) => {
    if (!library || !id) {
      throw new Error("Need connection and id");
    }
    const contract = new library.eth.Contract(TimeAndMaterialABi.abi as any, id);
    await contract.methods.restart(startDate, numberOfDays, hoursPerDay, rate).send({
      from: account,
    });

    const offer = await getOfferByContractAddress(id);
    await saveOffer({
      ...offer,
      state: States.NotStarted,
      // @ts-ignore
      startDate,
      numberOfDays,
      hoursPerDay,
    });
  };

export const addTimeEntry =
  ({
    library,
    id,
    account,
  }: {
    library?: Web3;
    id?: string;
    account?: string | null;
  }) =>
  async (data: { date: number; numberOfHours: number; description: string }) => {
    if (!library || !id) {
      throw new Error("Need connection and id");
    }
    const contract = new library.eth.Contract(TimeAndMaterialABi.abi as any, id);
    await contract.methods
      .addTimeEntry(data.date, data.numberOfHours, data.description)
      .send({
        from: account,
      });
  };

export const getTimeEntries =
  ({ library, id }: { library?: Web3; id?: string }) =>
  async () => {
    if (!library || !id) {
      throw new Error("Need connection and id");
    }
    const contract = new library.eth.Contract(TimeAndMaterialABi.abi as any, id);
    const data = await contract.methods.getTimeEntries().call();
    return data.map((item: any) => {
      const [date, numberOfHours, description, approved, released] = item;
      return {
        date,
        numberOfHours,
        description,
        approved,
        released,
      } as TimeEntry;
    });
  };

export const getAssignee =
  ({ library, id }: { library?: Web3; id?: string }) =>
  async () => {
    if (!library || !id) {
      throw new Error("Need connection and id");
    }
    const contract = new library.eth.Contract(TimeAndMaterialABi.abi as any, id);
    const assignee = await contract.methods.assignee().call();

    return assignee as string;
  };

export const getClient =
  ({ library, id }: { library?: Web3; id?: string }) =>
  async () => {
    if (!library || !id) {
      throw new Error("Need connection and id");
    }
    const contract = new library.eth.Contract(TimeAndMaterialABi.abi as any, id);
    const client = await contract.methods.client().call();

    return client as string;
  };

export const getRole =
  ({
    library,
    id,
    account,
  }: {
    library?: Web3;
    id?: string;
    account?: string | null;
  }) =>
  async () => {
    if (!library || !id) {
      throw new Error("Need connection and id");
    }
    const contract = new library.eth.Contract(TimeAndMaterialABi.abi as any, id);
    const [assignee, client] = await Promise.all([
      contract.methods.assignee().call(),
      contract.methods.client().call(),
    ]);

    return assignee === account
      ? Role.Assignee
      : client === account
      ? Role.Client
      : null;
  };

export const approveTimeEntry =
  ({
    library,
    id,
    account,
  }: {
    library?: Web3;
    id?: string;
    account?: string | null;
  }) =>
  async (data: { date: number }) => {
    if (!library || !id) {
      throw new Error("Need connection and id");
    }
    const contract = new library.eth.Contract(TimeAndMaterialABi.abi as any, id);
    await contract.methods.approveTimeEntry(data.date).send({
      from: account,
    });
  };

export const getAvailableReward =
  ({ library, id }: { library?: Web3; id?: string }) =>
  async () => {
    if (!library || !id) {
      throw new Error("Need connection and id");
    }
    const contract = new library.eth.Contract(TimeAndMaterialABi.abi as any, id);
    const data = await contract.methods.availableReward().call();
    return new BN(data);
  };

export const withdrawReward =
  ({
    library,
    id,
    account,
  }: {
    library?: Web3;
    id?: string;
    account?: string | null;
  }) =>
  async () => {
    if (!library || !id) {
      throw new Error("Need connection and id");
    }
    const contract = new library.eth.Contract(TimeAndMaterialABi.abi as any, id);
    await contract.methods.withdrawReward().send({
      from: account,
    });
  };

export const stopContract =
  ({
    library,
    id,
    account,
  }: {
    library?: Web3;
    id?: string;
    account?: string | null;
  }) =>
  async () => {
    if (!library || !id) {
      throw new Error("Need connection and id");
    }
    const contract = new library.eth.Contract(TimeAndMaterialABi.abi as any, id);
    await contract.methods.stop().send({
      from: account,
    });

    const offer = await getOfferByContractAddress(id);
    await saveOffer({
      ...offer,
      state: States.Stopped,
    });
  };

export const withdrawRemainingDeposit =
  ({
    library,
    id,
    account,
  }: {
    library?: Web3;
    id?: string;
    account?: string | null;
  }) =>
  async () => {
    if (!library || !id) {
      throw new Error("Need connection and id");
    }
    const contract = new library.eth.Contract(TimeAndMaterialABi.abi as any, id);
    await contract.methods.withdrawRemainingDeposit().send({
      from: account,
    });
  };

export const getToken =
  ({ library, id }: { library?: Web3; id?: string }) =>
  async () => {
    if (!library || !id) {
      throw new Error("Need connection and id");
    }
    try {
      const contract = new library.eth.Contract(ERC20TimeAndMaterialABi.abi as any, id);
      const data = await contract.methods._token().call();
      return { token: data as string };
    } catch (e) {
      return { token: undefined };
    }
  };

export const getAvailableRemainingDeposit =
  ({ library, id }: { library?: Web3; id?: string }) =>
  async () => {
    if (!library || !id) {
      throw new Error("Need connection and id");
    }
    const contract = new library.eth.Contract(TimeAndMaterialABi.abi as any, id);
    const result = await contract.methods.availableRemainingDeposit().call();
    return new BN(result);
  };

export const getGlobalRemainingDeposit =
  ({ library, id }: { library?: Web3; id?: string }) =>
  async () => {
    if (!library || !id) {
      throw new Error("Need connection and id");
    }
    const contract = new library.eth.Contract(TimeAndMaterialABi.abi as any, id);
    const result = await contract.methods.globalRemainingDeposit().call();
    return new BN(result);
  };

export const getAvailableFee =
  ({ library, id }: { library?: Web3; id?: string }) =>
  async () => {
    if (!library || !id) {
      throw new Error("Need connection and id");
    }
    const contract = new library.eth.Contract(TimeAndMaterialABi.abi as any, id);
    const result = await contract.methods.availableFee().call();
    return new BN(result);
  };

export const getCalcFee =
  ({ library, id }: { library?: Web3; id?: string }) =>
  async (deposit: string | undefined) => {
    if (!library || !id) {
      throw new Error("Need connection and id");
    }
    if (!deposit) {
      return new BN(0, 10);
    }
    const contract = new library.eth.Contract(TimeAndMaterialABi.abi as any, id);
    const result = await contract.methods.calcFee(deposit).call();
    return new BN(result);
  };
