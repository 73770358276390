import { Flex, Text } from "theme-ui";
import React from "react";
import copy from "copy-to-clipboard";
import { toast } from "react-hot-toast";

import { Icon } from "../../icon";

export enum TypesOfAddress {
  Contract = "Contract",
  Assignee = "Assignee",
  Client = "Client",
}

type Props = {
  address?: string;
  typeOfAddress?: TypesOfAddress;
};

export const BlockchainAddress = ({
  address,
  typeOfAddress = TypesOfAddress.Contract,
}: Props) => {
  return (
    <Flex
      sx={{
        alignItems: "center",
      }}
    >
      <Text
        variant="h3"
        sx={{
          width: "160px",
        }}
      >
        {typeOfAddress} address
      </Text>
      <Flex
        as="button"
        sx={{
          alignItems: "center",
          padding: 0,
          border: "none",
          background: "transparent",
          cursor: "pointer",
          color: "black",
          marginLeft: 6,
        }}
        onClick={() => {
          if (!address) {
            return;
          }
          copy(address, {
            format: "text/plain",
            onCopy: () => {
              toast.success("Address copied");
            },
          });
        }}
      >
        <Icon variant="Copy" />
        <Text
          sx={{
            fontSize: 2,
            fontWeight: "medium",
            marginLeft: 1,
          }}
        >
          {address}
        </Text>
      </Flex>
    </Flex>
  );
};
