import React from "react";
import Arrow from "./svg/arrow.svg";
import Check from "./svg/check.svg";
import Copy from "./svg/copy.svg";
import Cross from "./svg/cross.svg";
import Danger from "./svg/danger.svg";
import Hamburger from "./svg/hamburger.svg";
import Issue from "./svg/issue.svg";
import Logout from "./svg/logout.svg";
import Messages from "./svg/messages.svg";
import Plus from "./svg/plus.svg";
import Notifications from "./svg/notifications.svg";
import Projects from "./svg/projects.svg";
import Question from "./svg/question.svg";
import Tasks from "./svg/tasks.svg";
import User from "./svg/user.svg";
import Wallet from "./svg/wallet.svg";
import { Box, ThemeUIStyleObject } from "theme-ui";

const Icons = {
  Arrow,
  Check,
  Copy,
  Cross,
  Danger,
  Hamburger,
  Issue,
  Logout,
  Messages,
  Plus,
  Notifications,
  Projects,
  Question,
  Tasks,
  User,
  Wallet,
};

export type IconVariant = keyof typeof Icons;

export const Icon = (props: { variant: IconVariant; sx?: ThemeUIStyleObject }) => {
  const { variant, sx = {} } = props;
  return (
    <Box
      as={Icons[variant]}
      sx={{
        width: 4,
        height: 4,
        color: "inherit",
        ...sx,
      }}
    />
  );
};
