import React from "react";
import { Box, Flex } from "theme-ui";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import AsideMenu from "src/components/aside-menu";
import ContractsListConnected from "src/components/contracts-list";
import { ContractCard } from "src/components/contract-card";
import ContractForm from "src/components/contract-form";
import { Modal } from "src/components/modal";
import ContractView from "src/components/contract-view";
import TimeEntries from "src/components/time-entries";
import RestartingContractForm from "../../components/RestartingContractForm";

const DetailsRedirect = () => {
  const { id } = useParams<{ id: string }>();
  return <Redirect to={`/contracts/${id}/details`} />;
};

export const DashboardPage = () => {
  let history = useHistory();
  let location = useLocation();

  return (
    <Flex
      sx={{
        height: "100vh",
      }}
    >
      <AsideMenu />
      <ContractsListConnected />
      <Box
        sx={{
          flex: 1,
          "& > *": {
            display: "contents",
          },
        }}
      >
        <TransitionGroup>
          <CSSTransition
            key={location.pathname}
            classNames="modal-inner"
            timeout={{
              enter: 300,
              exit: 400,
            }}
          >
            <Switch location={location}>
              <Route
                path="/contracts/new"
                render={() => (
                  <Modal onClose={() => history.push("/contracts/", { replace: true })}>
                    <ContractCard title={"Create new contract"}>
                      <ContractForm />
                    </ContractCard>
                  </Modal>
                )}
              />
              <Route
                path="/contracts/draft/:id"
                render={() => (
                  <Modal onClose={() => history.push("/contracts/", { replace: true })}>
                    <ContractCard title={"Draft"}>
                      <ContractForm />
                    </ContractCard>
                  </Modal>
                )}
              />
              <Route path="/contracts/:id/details" render={() => <ContractView />} />
              <Route path="/contracts/:id/entries" render={() => <TimeEntries />} />
              <Route
                path="/contracts/:id/restart"
                render={() => (
                  <Modal onClose={() => history.push("/contracts/", { replace: true })}>
                    <ContractCard title={"Restating contract"}>
                      <RestartingContractForm />
                    </ContractCard>
                  </Modal>
                )}
              />
              <Route exact path="/contracts/:id" component={DetailsRedirect} />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </Box>
    </Flex>
  );
};
