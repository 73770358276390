import { Box, Button, Flex, Text } from "theme-ui";
import { Controller, useForm } from "react-hook-form";
import { CustomInput } from "../custom-form";
import { CustomDatePicker } from "../date-picker";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { wait, fromSolidity, toSolidity } from "@brainhubinc/commons";
import { toast } from "react-hot-toast";
import React, { useCallback } from "react";
import { addTimeEntry } from "@brainhubinc/api";
import { useParams } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { LoadingContent } from "../loading-content";
import { useMetadata } from "../../hooks/useMetadata";

export const TimeEntryForm = ({
  onSubmit,
  isLoading,
  hoursPerDay,
}: {
  onSubmit: (data: any) => void;
  isLoading?: boolean;
  hoursPerDay?: number;
}) => {
  const { handleSubmit, watch, control, reset } = useForm({
    defaultValues: {
      description: "",
      date: toSolidity(new Date()),
      numberOfHours: hoursPerDay || 0,
    },
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex
        sx={{
          alignItems: "flex-start",
        }}
      >
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <CustomInput
              {...field}
              placeholder="Description"
              sx={{
                flex: 1,
              }}
            />
          )}
        />
        <Flex
          sx={{
            marginLeft: 9,
            flex: 0.4,
            flexDirection: "column",
          }}
        >
          <Controller
            name="date"
            control={control}
            render={({ field }) => (
              <CustomDatePicker
                selected={field.value ? fromSolidity(field.value) : null}
                onChange={(date) => {
                  field.onChange({
                    target: {
                      value: date && toSolidity(date),
                    },
                  });
                }}
              />
            )}
          />
          <Text
            sx={{
              fontSize: 0,
              color: "grey600",
              mt: 1,
            }}
          >
            Day's timezone: UTC±00:00
          </Text>
        </Flex>

        <Controller
          name="numberOfHours"
          control={control}
          render={({ field }) => (
            <CustomInput
              {...field}
              placeholder="Hours"
              type="number"
              sx={{
                marginLeft: 2,
                flex: 0.2,
              }}
            />
          )}
        />
        <Button
          variant="primary"
          sx={{
            marginLeft: 4,
            height: 6,
          }}
          disabled={isLoading}
        >
          <LoadingContent isLoading={isLoading}>Add</LoadingContent>
        </Button>
      </Flex>
    </form>
  );
};

const TimeEntryFormConnected = () => {
  const { id } = useParams<{ id: string }>();
  const { library, chainId, account } = useWeb3React<Web3>();
  const queryClient = useQueryClient();

  const metadata = useMetadata(id);

  const addTimeEntryReq = useCallback(
    addTimeEntry({
      library,
      account,
      id,
    }),
    [library, account, id],
  );

  const addTimeEntryMutation = useMutation(addTimeEntryReq, {
    onSuccess: async (data) => {
      await wait(300);
      queryClient.invalidateQueries(["contract", "time-entries", id]);
      queryClient.invalidateQueries(["contract", "state", chainId, id]);
      toast.success(`Added time entry`);
      analytics.track("Add Time Entry successful", {
        id,
      });
    },
    onError: (error: any) => {
      toast.error(error.message);
      analytics.track("Add Time Entry fail", {
        id,
      });
    },
  });

  return (
    <TimeEntryForm
      isLoading={addTimeEntryMutation.isLoading}
      onSubmit={(data) => {
        addTimeEntryMutation.mutate(data);
        analytics.track("Add Time Entry call", {
          id,
          ...data,
        });
      }}
      hoursPerDay={metadata.data?.hoursPerDay}
    />
  );
};

export default TimeEntryFormConnected;
