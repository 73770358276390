import { useQuery } from "react-query";
import { getMetadata } from "@brainhubinc/api";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";

export const useMetadata = (id: string) => {
  const { library, chainId } = useWeb3React<Web3>();

  return useQuery(["contract", "data", chainId, id], getMetadata({ library, id }), {
    enabled: !!(id && library),
  });
};
