import { NetworkInfo } from "@brainhubinc/commons";
import { Box, Flex, Image, Text } from "theme-ui";
import React from "react";
import { ThemeUIStyleObject } from "@theme-ui/css";

export const NetworkModalItem = ({
  active,
  networkInfo,
  sx = {},
  onClick,
}: {
  active?: boolean;
  networkInfo: NetworkInfo;
  sx?: ThemeUIStyleObject;
  onClick?: () => void;
}) => {
  return (
    <Box
      sx={{
        padding: "1px",
        background: active
          ? "linear-gradient(91.34deg, #4D34B6 45.83%, #2E6C9F 100%)"
          : "white",
        overflow: "hidden",
        border: "none",
        width: "100%",
        ":hover": {
          background: "linear-gradient(91.34deg, #4D34B6 45.83%, #2E6C9F 100%)",
        },
        ...sx,
      }}
      as="button"
      onClick={onClick}
    >
      <Flex
        sx={{
          paddingY: 2,
          paddingX: 3,
          background: active ? "transparent" : "white",
          alignItems: "center",
        }}
      >
        <Image
          alt={networkInfo.params.chainName}
          src={networkInfo.icon}
          sx={{
            width: 4,
            height: 4,
            borderRadius: "round",
            overflow: "hidden",
          }}
        />
        <Text
          sx={{
            ml: 3,
            fontSize: 1,
            fontWeight: "medium",
            color: active ? "white" : "black",
          }}
        >
          {networkInfo.params.chainName}
        </Text>
      </Flex>
    </Box>
  );
};
