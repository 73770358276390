import React from "react";
import { Box, Heading, Flex, Text } from "theme-ui";
import { useWeb3React } from "@web3-react/core";
import { AbstractConnector } from "@web3-react/abstract-connector";
import { toast } from "react-hot-toast";

import { Logo } from "src/components/logo";
import { WalletConnectItem, WalletConnectList } from "src/components/wallet-connect";
import { wallets } from "src/helpers/_wallets";
import Web3 from "web3";
import { Helmet } from "react-helmet";

export const AuthPage = ({
  onConnect,
}: {
  onConnect?: (args: { connector: AbstractConnector; key: string }) => void;
}) => {
  return (
    <Box
      sx={{
        pl: [3, 8],
        pr: [3, 7],
      }}
    >
      <Helmet>
        <title>Auth | BrainHub</title>
      </Helmet>
      <Box
        sx={{
          pt: 5,
        }}
      >
        <Logo title="BrainHub" />
      </Box>
      <Flex
        sx={{
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Heading
          sx={{
            mt: [124, 112],
            fontSize: 5,
            fontWeight: "bold",
            color: "black",
            lineHeight: 1.4,
          }}
        >
          Connect your wallet
        </Heading>
        <Text
          sx={{
            mt: [3, 2],
            fontSize: 2,
            color: "black100",
            lineHeight: 1.4,
            textAlign: "center",
          }}
        >
          Connect with one of our available wallet providers or create a new one.
        </Text>

        <Box
          sx={{
            mt: 6,
            alignSelf: ["stretch", "center"],
          }}
        >
          <WalletConnectList>
            {wallets.map((wallet, id) => (
              <WalletConnectItem
                key={id}
                icon={wallet.icon}
                title={wallet.title}
                onClick={() => {
                  if (wallet.connector) {
                    onConnect &&
                      onConnect({
                        connector: wallet.connector,
                        key: wallet.key,
                      });
                  }
                }}
              />
            ))}
          </WalletConnectList>
        </Box>
      </Flex>
    </Box>
  );
};

const AuthPageConnected = () => {
  const { activate, library } = useWeb3React<Web3>();

  const setProvider = (type: string) => {
    window.localStorage.setItem("provider", type);
  };

  return (
    <AuthPage
      onConnect={async ({ connector, key }) => {
        try {
          await activate(connector, (error) => {
            debugger;
            console.log(error);
          });
          setProvider(key);
          toast.success("Success");

          // Track connection event
          const account = await connector.getAccount();
          const chainId = await connector.getChainId();

          analytics.identify(account || "", {
            provider: key,
            chainId,
          });
          analytics.track("Wallet Connected", {
            provider: key,
            chainId,
          });
        } catch (e) {
          toast.error((e as any).message);
        }
      }}
    />
  );
};

export default AuthPageConnected;
