import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { useQuery } from "react-query";
import { getAvailableRemainingDeposit } from "@brainhubinc/api";

export const useAvailableRemainingDeposit = (id: string) => {
  const { library, chainId } = useWeb3React<Web3>();

  return useQuery(
    ["availableRemainingDeposit", chainId, id],
    getAvailableRemainingDeposit({ library, id }),
    {
      enabled: !!(library && id),
    },
  );
};
