import { NetworkKey, networks, NetworkInfo } from "@brainhubinc/commons";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";

export const useNetworkInfo = (_chainId?: number) => {
  const { library, chainId } = useWeb3React<Web3>();

  if (_chainId) {
    return (
      (library &&
        (networks[library.utils.toHex(_chainId) as NetworkKey] as NetworkInfo)) ||
      undefined
    );
  }

  if (chainId) {
    return (
      (library &&
        (networks[library.utils.toHex(chainId) as NetworkKey] as NetworkInfo)) ||
      undefined
    );
  }
};
