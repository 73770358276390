import { Text } from "theme-ui";
import React from "react";

export const Hint = ({ title }: { title: string }) => {
  return (
    <Text
      sx={{
        marginLeft: 2,
        fontSize: 0,
        color: "grey600",
        lineHeight: "body",
        overflow: "hidden",
        whiteSpace: "nowrap",
        flexShrink: 0,
      }}
    >
      {title}
    </Text>
  );
};
