import { Box, Button, Flex, Label } from "theme-ui";
import { Helmet } from "react-helmet";
import { Controller, useForm } from "react-hook-form";
import { CustomInput, CustomTextarea } from "../custom-form";
import { CustomDatePicker } from "../date-picker";
import { fromSolidity, toSolidity, TokenInfo } from "@brainhubinc/commons";
import Web3 from "web3";
import { LoadingContent } from "../loading-content";
import React from "react";
import pick from "lodash.pick";
import { MetadataData } from "../../api/offers/contracts";
import { TokenBalance } from "../contract-form/components/TokenBalance";
import { useParams } from "react-router-dom";
import { useMetadata } from "../../hooks/useMetadata";
import { useWeb3React } from "@web3-react/core";
import { useOfferByContractAddressQuery } from "../../hooks/useOfferByContractAddressQuery";
import { ChangeNetwork } from "../contract-view/components/ChangeNetwork";
import { useCalcDeposit } from "../../hooks/useCalcDeposit";
import { useAssignee } from "../../hooks/useAssignee";
import { useRateDisplay } from "../../hooks/useRateDisplay";
import { useTokenInfo } from "../../hooks/useTokenInfo";
import { useToken } from "../../hooks/useToken";
import { useRestartMutation } from "../../hooks/useRestartMutation";

const initialValues = {
  numberOfDays: 0,
  hoursPerDay: 0,
  startDate: null,
};

type FormData = {
  numberOfDays?: number;
  hoursPerDay?: number;
  startDate: string | number | null;
};

const isNullValidation = (value?: number) => value === 0 || value! < 0;

export const RestartingContractForm = ({
  id,
  defaultValues,

  onRestart,
  isRestartLoading,
  tokenInfo,
  rateDisplay,
  assignee,
}: {
  id: string;
  defaultValues?: MetadataData;
  onRestart: (data: any) => void;
  isRestartLoading?: boolean;
  tokenInfo?: TokenInfo;
  library?: Web3;
  rateDisplay?: string;
  assignee?: string;
}) => {
  const serializedDefaultValues = defaultValues
    ? pick(defaultValues, Object.keys(initialValues))
    : undefined;

  const {
    handleSubmit,
    watch,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: serializedDefaultValues ? serializedDefaultValues : initialValues,
    mode: "all",
  });

  const numberOfDays = watch("numberOfDays", 0);
  const hoursPerDay = watch("hoursPerDay", 0);

  const { depositWithFeeDisplay } = useCalcDeposit({
    id,
    numberOfDays,
    hoursPerDay,
  });

  return (
    <Box>
      <Helmet>
        <title>Restarting contract draft | BrainHub</title>
      </Helmet>

      <Label htmlFor="title">Title</Label>
      <CustomInput readOnly value={defaultValues?.title} />

      <Label htmlFor="description">Description</Label>
      <CustomTextarea readOnly rows={4} value={defaultValues?.description} />

      <Label
        htmlFor="rate"
        sx={{
          "* + &": {
            marginTop: "72px",
          },
        }}
      >
        Rate
      </Label>

      <CustomInput readOnly value={rateDisplay} hint={tokenInfo?.symbol} />

      <Label htmlFor="title">Assignee address</Label>
      <CustomInput readOnly value={assignee} />
      <Box
        sx={{
          marginTop: 1,
        }}
      >
        <TokenBalance token={tokenInfo?.address} />
      </Box>

      <Label htmlFor="startOffer">Start date</Label>
      <Controller
        name="startDate"
        rules={{
          required: "Start date is required",
        }}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <CustomDatePicker
            selected={field.value ? fromSolidity(field.value) : null}
            error={error}
            onChange={(date) => {
              field.onChange({
                target: {
                  value: date && toSolidity(date),
                },
              });
            }}
          />
        )}
      />

      <Label htmlFor="numberOfDays">Contract duration</Label>
      <Flex
        sx={{
          width: "100%",
        }}
      >
        <Controller
          name="numberOfDays"
          control={control}
          rules={{
            required: "Contract duration is required",
            validate: (value) => {
              if (isNullValidation(value)) {
                return "Contract duration should be more than 0";
              }
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <CustomInput
              defaultValue="0"
              type="number"
              hint="working days"
              min="0"
              step="1"
              {...field}
              error={error}
              sx={{
                flex: 1,
              }}
            />
          )}
        />
        <Controller
          name="hoursPerDay"
          control={control}
          rules={{
            required: "Contract duration is required",
            validate: (value) => {
              if (isNullValidation(value)) {
                return "Contract duration should be more than 0";
              }
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <CustomInput
              defaultValue="0"
              type="number"
              hint="hours per day"
              min="0"
              step="1"
              {...field}
              error={error}
              sx={{
                flex: 1,
                marginLeft: 4,
              }}
            />
          )}
        />
      </Flex>

      <Label htmlFor="deposit">Required deposit</Label>
      <CustomInput readOnly hint={`fee included`} value={depositWithFeeDisplay} />

      <Flex
        sx={{
          mt: 6,
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        <Button
          variant={"primary"}
          name="start"
          disabled={isRestartLoading}
          onClick={handleSubmit(onRestart)}
          sx={{
            marginLeft: 4,
          }}
        >
          <LoadingContent isLoading={isRestartLoading}>Restart</LoadingContent>
        </Button>
      </Flex>
    </Box>
  );
};

const RestartingContractFormConnected = () => {
  const { id } = useParams<{ id: string }>();
  const metadata = useMetadata(id);
  const { chainId, library } = useWeb3React<Web3>();
  const offer = useOfferByContractAddressQuery(id);
  const assignee = useAssignee(id);
  const rateDisplay = useRateDisplay(id);
  const token = useToken(id);
  const tokenInfo = useTokenInfo(token?.data?.token || undefined);
  const restartMutation = useRestartMutation(id);

  if (
    typeof chainId !== "undefined" &&
    typeof offer.data?.chainId !== "undefined" &&
    "" + chainId != "" + offer.data?.chainId
  ) {
    return <ChangeNetwork offer={offer.data} />;
  }

  return (
    <RestartingContractForm
      id={id}
      defaultValues={metadata.data}
      onRestart={(data) =>
        restartMutation.mutate({
          ...data,
          rate: metadata.data?.rate,
        })
      }
      library={library}
      rateDisplay={rateDisplay}
      assignee={assignee[0]}
      tokenInfo={tokenInfo}
      isRestartLoading={restartMutation.isLoading}
    />
  );
};

export default RestartingContractFormConnected;
