import React from "react";
import { Flex, Box, Text } from "theme-ui";

import { rotate } from "src/constants";

import SpinnerIcon from "./spinner.svg";

export const LoadingContent = ({
  children,
  isLoading,
}: {
  children: React.ReactNode;
  isLoading?: boolean;
}) => {
  return (
    <Flex
      sx={{
        alignItems: "center",
      }}
    >
      <Text
        sx={{
          transform: isLoading ? "translateX(0)" : "translateX(10px)",
          transition: "transform .3s",
        }}
      >
        {children}
      </Text>
      {isLoading && (
        <Flex
          sx={{
            marginLeft: 2,
          }}
        >
          <Box
            as={SpinnerIcon}
            sx={{
              animation: `${rotate} 2s linear infinite`,
              width: "12px",
              height: "12px",
            }}
          />
        </Flex>
      )}
      {!isLoading && (
        <Flex
          sx={{
            marginLeft: 2,
          }}
        >
          <Box
            sx={{
              width: "12px",
            }}
          />
        </Flex>
      )}
    </Flex>
  );
};
