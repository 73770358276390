import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useIsMutating, useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-hot-toast";
import { useWeb3React } from "@web3-react/core";
import { Box } from "theme-ui";

import { wait } from "@brainhubinc/commons";
import { useNetworkInfo } from "src/hooks/useNetworkInfo";
import { saveOffer, startOffer, getFactoryParams, deleteOffer } from "@brainhubinc/api";
import { useOfferQuery } from "src/hooks/useOfferQuery";
import SpinnerIcon from "src/components/loading-content/spinner.svg";
import { rotate } from "src/constants";

import ContractForm from "./components/ContractForm";

const ContractFormConnected = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();

  const { library, account, chainId } = useWeb3React();

  const { data: offerQuery, isLoading } = useOfferQuery(id);
  const networkInfo = useNetworkInfo();

  const erc20TimeAndMaterialFactoryAddress =
    networkInfo?.erc20TimeAndMaterialFactoryAddress || null;

  const erc20factoryParams = useQuery(
    ["factoryParams", chainId, erc20TimeAndMaterialFactoryAddress],
    () =>
      getFactoryParams({
        library,
        timeAndMaterialFactoryAddress: erc20TimeAndMaterialFactoryAddress,
      }),
  );

  const etherTimeAndMaterialFactoryAddress =
    networkInfo?.etherTimeAndMaterialFactoryAddress || null;

  const etherFactoryParams = useQuery(
    ["factoryParams", chainId, etherTimeAndMaterialFactoryAddress],
    () =>
      getFactoryParams({
        library,
        timeAndMaterialFactoryAddress: etherTimeAndMaterialFactoryAddress,
      }),
  );

  const savingMutation = useMutation(saveOffer, {
    onSuccess: (data, variables) => {
      // Invalidate and refetch
      queryClient.invalidateQueries(["offers", "byClient"]);
      queryClient.invalidateQueries(["offer", "" + data.id]);
      if (!variables.id) {
        toast.success("Create a Draft");
        history.push("/contracts", { replace: true });
      } else {
        toast.success("Safe a Draft");
      }
    },
    onError: (error: any) => {
      toast.error(error.message);
    },
  });

  const isContractDeploy = useIsMutating(["contractDeploy"]);

  const startMutation = useMutation(
    startOffer({
      onSaveOffer: (offer) => {
        if (!id && offer?.id) {
          history.push("/contracts/draft/" + offer?.id, { replace: true });
        }
        queryClient.invalidateQueries(["offers", "byClient"]);
        toast.success("Safe a Draft");
      },
    }),
    {
      mutationKey: ["contractDeploy"],
      onSuccess: async (data) => {
        await wait(400);
        queryClient.invalidateQueries(["offers", "byClient"]);
        queryClient.invalidateQueries(["offer", "" + data.id]);
        toast.success(`Created a contract ${data.address.slice(0, 6)}...`);
        history.push("/contracts/" + data.address, { replace: true });
        analytics.track("Start Contract successful", {
          id: data.address,
        });
      },
      onError: (error: any) => {
        toast.error(error.message);
        analytics.track("Start Contract fail");
      },
    },
  );

  const deleteMutation = useMutation(deleteOffer, {
    onSuccess: async (data) => {
      await wait(400);
      queryClient.invalidateQueries(["offers", "byClient"]);
      toast.success(`Draft deleted`);
      history.push("/", { replace: true });
    },
    onError: (error: any) => {
      toast.error(error.message);
    },
  });

  return isLoading ? (
    <Box
      as={SpinnerIcon}
      sx={{
        animation: `${rotate} 2s linear infinite`,
        path: {
          stroke: "black",
        },
        width: "24px",
        height: "24px",
      }}
    />
  ) : (
    <ContractForm
      id={id}
      defaultValues={offerQuery || undefined}
      isStartLoading={startMutation.isLoading || isContractDeploy > 0}
      isSaveLoading={savingMutation.isLoading}
      accountAddress={account}
      onSave={(data) => {
        savingMutation.mutate({
          ...(id ? { id } : {}),
          client: account,
          ...data,
        });
        analytics.track("Save Draft call", {
          ...data,
        });
      }}
      onStart={(data) => {
        startMutation.mutate({
          account,
          library,
          chainId: chainId ? "" + chainId : undefined,
          erc20TimeAndMaterialFactoryAddress,
          etherTimeAndMaterialFactoryAddress,
          networkInfo,
          offer: { ...(id ? { id } : {}), client: account, ...data },
        });
        analytics.track("Start Contract call", {
          chainId: chainId ? "" + chainId : undefined,
          ...data,
        });
      }}
      onDelete={() => {
        deleteMutation.mutate({ id });
        analytics.track("Delete Draft");
      }}
      erc20feePercentage={erc20factoryParams.data?.feePercentageNormalized || 0}
      etherFeePercentage={etherFactoryParams.data?.feePercentageNormalized || 0}
      chainId={chainId}
    />
  );
};

export default ContractFormConnected;
