import { useQuery } from "react-query";
import { getState } from "@brainhubinc/api";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";

export const useContractState = (id: string) => {
  const { library, chainId } = useWeb3React<Web3>();
  return useQuery(["contract", "state", chainId, id], getState({ library, id }), {
    enabled: !!(id && library),
  });
};
