import { useQuery } from "react-query";
import { getRole } from "@brainhubinc/api";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";

export const useRole = (id: string) => {
  const { library, account, chainId } = useWeb3React<Web3>();
  return useQuery(
    ["contract", "role", chainId, id],
    getRole({ library, id, account }),
    {
      enabled: !!(id && library && account),
    },
  );
};
