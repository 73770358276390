import { EthereumMainnet } from "./networks/EthereumMainnet";
import { GoerliTestnet } from "./networks/GoerliTestnet";
import { AuroraTestnet } from "./networks/AuroraTestnet";
import { AuroraMainnet } from "./networks/AuroraMainnet";
import { MoonbaseTestnet } from "./networks/MoonbaseTestnet";
import { Moonbeam } from "./networks/Moonbeam";

const Mainnets = {
  // Ethereum
  "0x1": EthereumMainnet,
  // Moonbeam
  "0x504": Moonbeam,
};

const Testnets = {
  // Ethereum
  "0x5": GoerliTestnet,
  // Moonbase
  "0x507": MoonbaseTestnet,
};

export const networks = process.env.IS_MAINNET ? Mainnets : Testnets;

export const allNetworks = {
  ...Mainnets,
  ...Testnets,
};

export type NetworkKey = keyof typeof networks;
