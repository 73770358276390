import { OfferData } from "@brainhubinc/api";
import React from "react";
import { FrameContainer } from "./FrameContainer";
import { Box, Button, Flex, Text } from "theme-ui";
import { ContractCard } from "../../contract-card";
import { MetadataView } from "./MetadataView";
import { useNetworkInfo } from "src/hooks/useNetworkInfo";
import { useTokenInfo } from "src/hooks/useTokenInfo";
import { NetworkInfo, TokenInfo, NetworkKey } from "@brainhubinc/commons";
import { switchNetwork } from "../../../helpers/switchNetwork";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { toast } from "react-hot-toast";
import { Helmet } from "react-helmet";
import { AttentionIcon } from "../../attention-icon";

export const ChangeNetwork = ({
  offer,
  token,
  network,
  rateDisplay,
  onClickChangeNetwork,
}: {
  offer?: OfferData;
  token?: TokenInfo;
  network?: NetworkInfo;
  rateDisplay?: string;
  onClickChangeNetwork?: () => void;
}) => {
  return (
    <FrameContainer>
      <Box
        sx={{
          flex: 1,
        }}
      >
        <Helmet>{offer && <title>{offer.title} | BrainHub</title>}</Helmet>
        <ContractCard title={(offer && offer.title) || ""}>
          <Box
            sx={{
              padding: 3,
              display: "grid",
              gridTemplateColumns: "auto 1fr auto",
              gridAutoRows: "auto",
              gridTemplateAreas: '"icon message button" ". hint button"',
              alignItems: "center",
              columnGap: 3,
              rowGap: 1,
              background: "#F5F5FA",
            }}
          >
            <Box
              sx={{
                gridArea: "icon",
              }}
            >
              <AttentionIcon small />
            </Box>

            <Box
              sx={{
                gridArea: "message",
              }}
            >
              <Text
                sx={{
                  fontSize: 1,
                  fontWeight: "bold",
                  lineHeight: "body",

                  color: "#F45C43",
                }}
              >
                The contract was created in {network?.params.chainName || "unknown"}{" "}
                network
              </Text>
            </Box>

            <Box
              sx={{
                gridArea: "hint",
              }}
            >
              <Text
                sx={{
                  fontSize: 1,
                  lineHeight: "body",
                }}
              >
                Change network to continue
              </Text>
            </Box>

            <Box
              sx={{
                gridArea: "button",
              }}
            >
              <Button variant="primary" onClick={onClickChangeNetwork}>
                Change network
              </Button>
            </Box>
          </Box>

          {offer && (
            <MetadataView
              metadata={{
                ...offer,
                startDate: offer.startOffer ? parseInt("" + offer.startOffer) : null,
              }}
              state={offer.state || 0}
              token={token}
              rateDisplay={rateDisplay}
            />
          )}
        </ContractCard>
      </Box>
    </FrameContainer>
  );
};

const ChangeNetworkConnected = ({ offer }: { offer?: OfferData }) => {
  const tokenInfo = useTokenInfo(
    offer?.token || undefined,
    offer?.chainId ? parseInt(offer.chainId) : undefined,
  );
  const networkInfo = useNetworkInfo(
    offer?.chainId ? parseInt(offer.chainId) : undefined,
  );
  const { library } = useWeb3React<Web3>();

  return (
    <ChangeNetwork
      offer={offer}
      token={tokenInfo}
      network={networkInfo}
      rateDisplay={offer?.rate ? "" + offer.rate : undefined}
      onClickChangeNetwork={async () => {
        if (!library || !offer?.chainId) {
          return;
        }
        await switchNetwork(library, library.utils.toHex(offer.chainId) as NetworkKey);
        toast.success("Network switched");
      }}
    />
  );
};

export default ChangeNetworkConnected;
