import React from "react";
import { Flex, Image, Text } from "theme-ui";
import { TokenInfo } from "@brainhubinc/commons";

export const BalanceContainer = ({
  children,
  token,
}: {
  children: React.ReactNode;
  token?: TokenInfo;
}) => {
  return (
    <Flex
      sx={{
        paddingX: 3,
        height: 6,
        background: "white",
        borderRadius: "base",
        alignItems: "center",
      }}
    >
      {children}
      {token && (
        <>
          <Image
            src={token.logoURI}
            sx={{
              marginLeft: 2,
              width: "20px",
              height: "20px",
            }}
          />
          <Text
            sx={{
              fontSize: 1,
              fontWeight: "bold",
              lineHeight: "body",
              marginLeft: 2,
            }}
          >
            {token.symbol}
          </Text>
        </>
      )}
    </Flex>
  );
};
