import { useCallback } from "react";
import { useQuery } from "react-query";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { toEther } from "@brainhubinc/commons";
import { Text } from "theme-ui";
import { useTokenInfo } from "../../../hooks/useTokenInfo";
import { getBalance } from "@brainhubinc/api";
import React from "react";

export const TokenBalance = ({ token }: { token?: string }) => {
  const { library, chainId, account } = useWeb3React<Web3>();
  const tokenInfo = useTokenInfo(token);
  const balancesReq = useCallback(getBalance({ library, account, token }), [
    library,
    token,
    account,
  ]);
  const balance = useQuery(["balances", chainId, account, token], balancesReq);

  return (
    <Text
      sx={{
        fontSize: 0,
        color: "grey600",
      }}
    >
      Your balance:{" "}
      {balance.data &&
        tokenInfo &&
        Web3.utils.fromWei(toEther(balance.data, tokenInfo.decimals))}{" "}
      {tokenInfo?.symbol}
    </Text>
  );
};
