import * as ERC20ABi from "@brainhubinc/commons/src/contracts/ERC20.json";
import Web3 from "web3";

export const getBalance =
  ({
    library,
    account,
    token,
  }: {
    library?: Web3;
    account?: string | null;
    token: string | undefined;
  }) =>
  async () => {
    if (!library || !account) {
      return;
    }
    if (token) {
      const contract = new library.eth.Contract(ERC20ABi as any, token);
      return contract.methods.balanceOf(account).call();
    } else {
      return library.eth.getBalance(account);
    }
  };
