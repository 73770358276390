import React from "react";
import { Box, Text } from "theme-ui";

export const StatusBadge = ({
  text,
  variant,
}: {
  text: string;
  variant: "danger" | "success";
}) => {
  return (
    <Box
      sx={{
        paddingX: 3,
        paddingY: 1,
        borderRadius: "32px",
        background: variant === "danger" ? "#F6D8D4" : "#E4F9CB",
        color: variant === "danger" ? "#F45C43" : "#56AB2F",
      }}
    >
      <Text
        sx={{
          fontSize: 1,
          fontWeight: "medium",
          lineHeight: "body",
        }}
      >
        {text}
      </Text>
    </Box>
  );
};
