import ReactDOM from "react-dom";
import { App } from "./App";
import theme from "./theme";
import { ThemeProvider } from "@emotion/react";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";
import React from "react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Helmet } from "react-helmet";
import * as FullStory from "@fullstory/browser";
import SentryFullStory from "@sentry/fullstory";

const SENTRY_DSN = process.env.SENTRY_DSN;
const FS_ORG_ID = process.env.FS_ORG_ID;
const IS_PROD = process.env.IS_PROD;

if (IS_PROD && FS_ORG_ID) {
  FullStory.init({ orgId: FS_ORG_ID });
}

if (IS_PROD && SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      new BrowserTracing(),
      new SentryFullStory("https://sentry.io/organizations/brainhub"),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const queryClient = new QueryClient();
const app = document.getElementById("app");

const getLibrary = (provider: any) => {
  return new Web3(provider);
};

ReactDOM.render(
  <BrowserRouter>
    <Web3ReactProvider getLibrary={getLibrary}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <Helmet>
            <title>Contracts | BrainHub</title>
          </Helmet>
          <App />
          <Toaster position="bottom-right" />
        </ThemeProvider>
      </QueryClientProvider>
    </Web3ReactProvider>
  </BrowserRouter>,
  app,
);
