import { useQuery } from "react-query";
import { getToken } from "@brainhubinc/api";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";

export const useToken = (id: string) => {
  const { library, chainId } = useWeb3React<Web3>();
  return useQuery(["contract", "token", chainId, id], getToken({ library, id }), {
    enabled: !!(id && library),
  });
};
