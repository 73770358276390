import { Text } from "theme-ui";
import React from "react";
import { FieldError } from "react-hook-form";

export const Error = ({ error }: { error: FieldError }) => {
  return (
    <Text
      sx={{
        mt: 1,
        fontSize: 0,
        lineHeight: "body",
        overflow: "hidden",
        whiteSpace: "nowrap",
        flexShrink: 0,
        display: "block",
        color: "#F45C43",
      }}
    >
      {error.message}
    </Text>
  );
};
