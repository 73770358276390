import React from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Flex, Text } from "theme-ui";

import { TokenInfo } from "@brainhubinc/commons";
import { useToken } from "src/hooks/useToken";
import { useTokenInfo } from "src/hooks/useTokenInfo";
import { useRequiredDepositDisplay } from "src/hooks/useRequiredDepositDisplay";
import { useStopMutation } from "src/hooks/useStopMutation";
import { useAssignee } from "src/hooks/useAssignee";

import { BlockchainAddress, TypesOfAddress } from "../components/BlockchainAddress";
import { BalanceContainer } from "../components/BalanceContainer";
import { ControlContainer } from "../components/ControlContainer";
import { LoadingContent } from "../../loading-content";
import { useIsMutating } from "react-query";

type Props = {
  contractAddress?: string;
  assigneeAddress?: string;
  token?: TokenInfo;
  onStop?: () => void;
  isLoading?: boolean;
  requiredDepositDisplay?: string;
};

export const ControlStop = ({
  contractAddress,
  assigneeAddress,
  token,
  onStop,
  isLoading,
  requiredDepositDisplay,
}: Props) => {
  return (
    <ControlContainer>
      <BlockchainAddress address={contractAddress} />
      {assigneeAddress ? (
        <Box
          sx={{
            marginTop: 3,
          }}
        >
          <BlockchainAddress
            address={assigneeAddress}
            typeOfAddress={TypesOfAddress.Assignee}
          />
        </Box>
      ) : null}
      <Box
        sx={{
          marginTop: 6,
        }}
      >
        <Text variant="h3">Deposit</Text>
        <Flex
          sx={{
            marginTop: 3,
            justifyContent: "space-between",
          }}
        >
          <BalanceContainer token={token}>
            <Text>
              {typeof requiredDepositDisplay !== "undefined"
                ? requiredDepositDisplay
                : "Unknown"}
            </Text>
          </BalanceContainer>
          <Flex
            sx={{
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                marginLeft: 3,
              }}
            >
              <Button variant="primary" onClick={onStop} disabled={isLoading}>
                <LoadingContent isLoading={isLoading}>
                  Stop and withdraw remaining deposit
                </LoadingContent>
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Box>
    </ControlContainer>
  );
};

const ControlStopConnected = () => {
  const { id } = useParams<{ id: string }>();
  const token = useToken(id);
  const tokenInfo = useTokenInfo(token?.data?.token || undefined);
  const requiredDepositDisplay = useRequiredDepositDisplay(id);
  const stopMutation = useStopMutation(id);
  const [assigneeAddress] = useAssignee(id);

  const isWithdrawRemainingDepositMutating = useIsMutating([
    "withdrawRemainingDeposit",
    id,
  ]);

  return (
    <ControlStop
      contractAddress={id}
      assigneeAddress={assigneeAddress}
      token={tokenInfo}
      onStop={() => {
        stopMutation.mutate();
        analytics.track("Stop Contract call", {
          id,
        });
      }}
      isLoading={stopMutation.isLoading || isWithdrawRemainingDepositMutating > 0}
      requiredDepositDisplay={requiredDepositDisplay}
    />
  );
};

export default ControlStopConnected;
