import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { Box } from "theme-ui";
import { Helmet } from "react-helmet";

import { useIsEnoughDeposit } from "src/hooks/useIsEnoughDeposit";
import { useMetadata } from "src/hooks/useMetadata";
import { useRole } from "src/hooks/useRole";
import { useContractState } from "src/hooks/useContractState";
import { MetadataData, Role, States } from "@brainhubinc/api";
import { useOfferByContractAddressQuery } from "src/hooks/useOfferByContractAddressQuery";

import MetadataView from "./components/MetadataView";
import ControlAssignee from "./control/ControlAssignee";
import ControlAttachingDeposit from "./control/ControlAttachingDeposit";
import ControlStart from "./control/ControlStart";
import ControlStop from "./control/ControlStop";
import ControlWithdrawRemainingDeposit from "./control/ControlWithdrawRemainingDeposit";
import { FrameContainer } from "./components/FrameContainer";
import ChangeNetwork from "./components/ChangeNetwork";

import { ContractCard } from "../contract-card";
import { TabItem, Tabs } from "../tabs";

export const ContractView = ({
  metadata,
  state,
  metadataView,
  children,
}: {
  metadata?: MetadataData;
  state: number;
  metadataView: React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <FrameContainer>
      <Helmet>{metadata && <title>{metadata.title + " | BrainHub"}</title>}</Helmet>
      <Box
        sx={{
          flex: 1,
        }}
      >
        <ContractCard title={(metadata && metadata.title) || ""}>
          <Box>
            <Tabs>
              <TabItem title="Details" active />
              {(state == States.Active || state == States.Stopped) && (
                <Link
                  to="entries"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <TabItem title="Time Entries" />
                </Link>
              )}
            </Tabs>
            <Box
              sx={{
                marginTop: 4,
              }}
            >
              {metadataView}
            </Box>
          </Box>
        </ContractCard>
      </Box>

      {children}
    </FrameContainer>
  );
};

const ContractViewConnected = () => {
  const { id } = useParams<{ id: string }>();
  const { chainId } = useWeb3React<Web3>();
  const metadata = useMetadata(id);
  const role = useRole(id);
  const state = useContractState(id);
  const isEnoughDeposit = useIsEnoughDeposit(id);
  const offer = useOfferByContractAddressQuery(id);

  if (
    typeof chainId !== "undefined" &&
    typeof offer.data?.chainId !== "undefined" &&
    "" + chainId != "" + offer.data?.chainId
  ) {
    return <ChangeNetwork offer={offer.data} />;
  }

  return (
    <ContractView
      metadata={metadata.data}
      state={state.data || States.NotStarted}
      metadataView={<MetadataView id={id} />}
    >
      {/* TODO: To refactor components below and move similar parts of the code to one component */}
      {role.data == Role.Client && (
        <>
          {state.data == States.NotStarted && !isEnoughDeposit && (
            <ControlAttachingDeposit />
          )}
          {state.data == States.NotStarted && isEnoughDeposit && <ControlStart />}
          {state.data == States.Active && <ControlStop />}
          {state.data == States.Stopped && <ControlWithdrawRemainingDeposit />}
        </>
      )}
      {role.data == Role.Assignee && <ControlAssignee />}
    </ContractView>
  );
};

export default ContractViewConnected;
