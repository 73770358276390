import { useBalance } from "./useBalance";
import { useAvailableReward } from "./useAvailableReward";
import { useAvailableFee } from "./useAvailableFee";
import { useGlobalRemainingDeposit } from "./useGlobalRemainingDeposit";
import { useRequiredDeposit } from "./useRequiredDeposit";
import BN from "bn.js";

export const useMissingBalance = (id: string) => {
  const globalRemainingDeposit = useGlobalRemainingDeposit(id);
  const requiredDeposit = useRequiredDeposit(id);
  const balance = useBalance(id);
  const availableReward = useAvailableReward(id);
  const availableFee = useAvailableFee(id);

  if (requiredDeposit && globalRemainingDeposit.data) {
    if (requiredDeposit.lte(globalRemainingDeposit.data)) {
      return new BN(0, 10);
    } else if (
      balance.data &&
      requiredDeposit &&
      availableReward.data &&
      availableFee.data
    ) {
      const diff = new BN(balance.data, 10)
        .sub(requiredDeposit)
        .sub(availableReward.data)
        .sub(availableFee.data);
      if (diff.lt(new BN(0, 10))) {
        return diff.neg();
      } else {
        return new BN(0, 10);
      }
    }
  }
  return;
};
