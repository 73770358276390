import { calculateRequiredDeposit } from "@brainhubinc/api";
import { useMetadata } from "./useMetadata";
import { useFactoryParams } from "./useFactoryParams";

export const useRequiredDeposit = (id: string) => {
  const metadata = useMetadata(id);
  const factoryParams = useFactoryParams(id);

  return (
    metadata.data &&
    factoryParams.data &&
    calculateRequiredDeposit({
      metadata: metadata.data,
      feePercentage: factoryParams.data.feePercentage,
      feeDecimals: factoryParams.data.feeDecimals,
    })
  );
};
