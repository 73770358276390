import React from "react";
import { Box, Flex, Text } from "theme-ui";
import { Icon } from "../../../icon";

export const FutureItem = ({ title }: { title: string }) => {
  return (
    <Flex
      sx={{
        alignItems: "center",
        "* + &": {
          position: "relative",
          marginTop: 4,
          ":before": {
            content: '""',
            position: "absolute",
            width: "1.5px",
            height: "17px",
            background: "grey200",
            mask: `url("data:image/svg+xml;utf8,<svg width='16' height='32' viewBox='0 0 16 32' xmlns='http://www.w3.org/2000/svg'><circle cx='8' cy='8' r='8' fill='currentColor'/></svg>")`,
            maskSize: "contain",
            top: "-20px",
            left: "11.5px",
          },
        },
      }}
    >
      <Flex
        sx={{
          alignItems: "center",
          justifyItems: "center",
          borderRadius: "round",
          background: "grey600",
          color: "white",
        }}
      >
        <Icon variant={"Check"} />
      </Flex>

      <Text
        sx={{
          marginLeft: 3,
          fontSize: 2,
          lineHeight: "body",
          color: "grey600",
        }}
      >
        {title}
      </Text>
    </Flex>
  );
};
