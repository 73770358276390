import React from "react";
import { Flex, ThemeUIStyleObject } from "theme-ui";

export { TabItem } from "./TabItem";

export const Tabs = ({
  children,
  sx,
}: {
  children: React.ReactNode;
  sx?: ThemeUIStyleObject;
}) => {
  return (
    <Flex
      sx={{
        ...sx,
        "> * + *": {
          marginLeft: 3,
        },
      }}
    >
      {children}
    </Flex>
  );
};
