import React from "react";
import { Flex } from "theme-ui";

export const FrameContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Flex
      sx={{
        width: "100%",
        height: "100%",
        flexDirection: "column",
        overflowY: "auto",

        "&.modal-inner-enter": {
          position: "absolute",
          top: 0,
          zIndex: 1,
          transform: "translateX(100%)",
        },
        "&.modal-inner-enter-active": {
          transform: "translateX(0)",
          transition: "transform 400ms",
        },
        "&.modal-inner-exit ": {
          position: "absolute",
          top: 0,
          opacity: 1,
        },
        "&.modal-inner-exit-active": {
          opacity: 0,
          transition: "opacity 350ms ease-in",
        },
      }}
    >
      {children}
    </Flex>
  );
};
